import { Col, Input, Row, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import { Form, Rules } from "../form";
import { AddressInput } from "../shared";
import { useCurrentUser } from "../../lib/hooks";
import { formatTaxIDLabel } from "../../lib/formats";

function CounterpartyBasic() {
  const { currentTenant } = useCurrentUser();

  return (
    <Row>
      <Col xs={24} md={12}>
        <Row gutter={32}>
          <Col span={24}>
            <Typography.Text type="secondary">
              <FormattedMessage
                id="counterparties.basicInfo.hint"
                defaultMessage="basicInfo.hint"
              />
            </Typography.Text>

            <Form.Item
              name="name"
              label={
                <FormattedMessage
                  id="displayName"
                  defaultMessage="displayName"
                />
              }
              rules={[Rules.required]}
              style={{ marginTop: "16px" }}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="businessName"
              label={
                <FormattedMessage
                  id="counterparties.businessName"
                  defaultMessage="businessName"
                />
              }
              rules={[Rules.required]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="taxIdNumber"
              label={formatTaxIDLabel(currentTenant)}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="internalId"
              label={
                <FormattedMessage id="internalId" defaultMessage="internalId" />
              }
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Typography.Title level={4}>
              <FormattedMessage
                id="counterparties.contactInfo"
                defaultMessage="contactInfo"
              />
            </Typography.Title>

            <Typography.Text type="secondary">
              <FormattedMessage
                id="counterparties.contactInfo.hint"
                defaultMessage="contactInfo.hint"
              />
            </Typography.Text>

            <AddressInput name="addressAttributes" />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default CounterpartyBasic;

import { FormattedMessage, useIntl } from "react-intl";
import {
  TaxComponentFields,
  TaxComponentChanges,
  TaxComponentFragment,
  TaxComponentShortFragment,
  TaxPlanComponentFragment,
} from "../../../lib/graphql";
import {
  DefaultTaxPlanVariables,
  useCurrentLocale,
  useTaxComponentCreate,
  useTaxComponentUpdate,
  useTaxComponents,
} from "../../../lib/hooks";
import { Form, FormulaHelp, Rules } from "../../form";
import { ItemFieldFormConfig, ItemForm } from "../../shared/ListView/ItemForm";
import { TranslationsInput, LocaleEmoji } from "../../users";
import { EditForm, HelpTooltip, NewForm, SidebarHeader } from "../../shared";
import {
  localeNames,
  localeDescriptions,
  localeNameField,
  localizedFieldName,
} from "../../../lib/locale";
import { Input } from "antd";
import {
  formatEntityNameLabel,
  insertText,
  translate,
} from "../../../lib/formats";

export function TaxComponentForm({
  availableComponents,
  taxComponent,
  onClose,
}: {
  availableComponents?: TaxComponentShortFragment[];
  taxComponent?: TaxComponentFragment;
  onClose: (result?: TaxComponentFragment) => void;
}) {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { currentLocale } = useCurrentLocale();
  const entityName = intl.formatMessage({ id: "taxComponents.entityName" });

  const renderTranslations = () => (
    <TranslationsInput
      key="translations"
      render={(locale) => (
        <>
          <TranslationsInput.NameInput locale={locale} />
          <TranslationsInput.DescriptionInput locale={locale} />
        </>
      )}
    />
  );

  const fields: ItemFieldFormConfig<
    TaxComponentFields | TaxComponentChanges
  >[] = [
    {
      label: formatEntityNameLabel(entityName),
      name: taxComponent ? localeNameField(currentLocale) : "name",
      type: "string",
      prefix: <LocaleEmoji.Current />,
      rules: [Rules.required],
      inputProps: { maxLength: 50 },
    },
    {
      label: <FormattedMessage id="abbr" />,
      name: "abbr",
      type: "text",
      rules: [Rules.required],
      inputProps: { style: { fontFamily: "monospace" } },
      formItemProps: {
        extra: (
          <FormattedMessage
            id="taxComponents.abbr.hint"
            defaultMessage="hint"
          />
        ),
      },
    },
    {
      label: <FormattedMessage id="formula" />,
      name: "defaultFormula",
      type: "custom",
      render: () => (
        <HelpTooltip
          overlayInnerStyle={{
            maxHeight: "90vh",
            overflowY: "scroll",
          }}
          title={
            <FormulaHelpContent
              taxes={availableComponents}
              onClick={(code) =>
                form.setFieldValue(
                  "defaultFormula",
                  insertText("textarea#formula", code)
                )
              }
            />
          }
        >
          <Form.Item name="defaultFormula" rules={[Rules.required]}>
            <Input.TextArea
              style={{ fontFamily: "monospace" }}
              id="formula"
              rows={4}
            />
          </Form.Item>
        </HelpTooltip>
      ),
    },
    {
      label: <FormattedMessage id="description" />,
      name: localizedFieldName("description", currentLocale),
      type: "text",
    },
    {
      type: "custom",
      render: renderTranslations,
    },
  ];

  if (taxComponent) {
    return (
      <EditForm
        formProps={{ form }}
        item={taxComponent}
        entityName={entityName}
        mutation={useTaxComponentUpdate}
        onMutate={(result) => result.taxComponentUpdate}
        onCancel={onClose}
        onSuccess={(result) => onClose(result)}
        formValues={({
          __typename,
          id,
          discardedAt,
          mayDestroy,
          mayDiscard,
          taxCreditAccount,
          taxDebitAccount,
          ...c
        }) => ({
          ...c,
          taxCreditAccountId: taxCreditAccount?.id,
          taxDebitAccountId: taxDebitAccount?.id,
          ...localeNames(c),
          ...localeDescriptions(c),
        })}
        fields={fields}
      />
    );
  }

  return (
    <NewForm
      formProps={{ form }}
      entityName={entityName}
      mutation={useTaxComponentCreate}
      onMutate={(result) => result.taxComponentCreate}
      mutationOptions={{ refetchQueries: ["TaxComponents"] }}
      formValues={() => ({} as TaxComponentFields)}
      onClose={onClose}
      fields={fields}
    />
  );
}

export function TaxPlanComponentForm({
  availableComponents,
  component,
  onClose,
}: {
  availableComponents?: TaxComponentShortFragment[];
  component: TaxPlanComponentFragment;
  onClose: (result?: TaxPlanComponentFragment) => void;
}) {
  return (
    <>
      <SidebarHeader
        title={
          <FormattedMessage
            id="edit.header"
            values={{ entityName: component.taxComponent.name }}
          />
        }
        onClose={onClose}
      />

      <ItemForm
        initialValues={
          {
            formula: component.formula || component.taxComponent.defaultFormula,
            useInValuation: component.useInValuation,
          } as TaxPlanComponentFragment
        }
        item={component}
        fields={[
          {
            label: <FormattedMessage id="formula" />,
            name: "formula",
            type: "custom",
            render: () => (
              <HelpTooltip
                overlayInnerStyle={{
                  maxHeight: "90vh",
                  overflowY: "scroll",
                }}
                title={
                  <FormulaHelpContent
                    taxes={availableComponents}
                    onClick={(code) => insertText("textarea#formula", code)}
                  />
                }
              >
                <Form.Item name="formula" rules={[Rules.required]}>
                  <Input.TextArea
                    style={{ fontFamily: "monospace" }}
                    id="formula"
                    rows={4}
                  />
                </Form.Item>
              </HelpTooltip>
            ),
          },
          {
            label: <FormattedMessage id="taxComponents.useInValuation" />,
            name: "useInValuation",
            type: "switch",
          },
        ]}
        onCancel={onClose}
        onSave={(values) =>
          Promise.resolve().then(() => {
            onClose(values);
            return null;
          })
        }
      />
    </>
  );
}

function FormulaHelpContent({
  taxes,
  onClick,
}: {
  taxes?: TaxComponentShortFragment[];
  onClick: (code: string) => void;
}) {
  const { items } = useTaxComponents({
    variables: { pageSize: 1000 },
    skip: taxes != null,
  });

  return (
    <FormulaHelp
      components={(taxes || items)?.map((c) => ({
        name: c.abbr,
        title: c.name,
      }))}
      inputVariables={Object.keys(DefaultTaxPlanVariables).map((v) => ({
        name: v,
        title: translate(`taxPlans.inputs.${v}`),
      }))}
      formulaText={<FormattedMessage id="taxComponents" />}
      onClick={onClick}
    />
  );
}

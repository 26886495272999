import { sumBy } from "lodash";
import { WorkOrderBuilder } from ".";
import {
  WorkOrderCostCenterFragment,
  WorkOrderMachineFragment,
} from "../../../../lib/graphql";
import { Destroy } from "../../../../lib/hooks";
import { WorkOrderBaseBuilder } from "./base";
import { MachineProgress } from "./progress/machineProgress";
import { MachineMetricBuilder } from "./metrics/machineMetrics";

export interface WorkOrderMachine extends Destroy<WorkOrderMachineFragment> {
  totalProgress?: number;
}

export class WorkOrderMachineBuilder extends WorkOrderBaseBuilder<
  Destroy<WorkOrderMachine>
> {
  progress: MachineProgress;
  metrics: MachineMetricBuilder;

  constructor(builder: WorkOrderBuilder) {
    super(builder);

    this.progress = new MachineProgress(builder);
    this.metrics = new MachineMetricBuilder(builder);
  }

  protected get field() {
    return "machines";
  }

  sorter(a: WorkOrderMachine, b: WorkOrderMachine) {
    return a.machine.name.localeCompare(b.machine.name);
  }

  getTotalHours() {
    const machines = this.get();
    return sumBy(machines, (e) => e.workHours);
  }

  getTotalProgress() {
    const machines = this.get();
    return sumBy(machines, (m) => m.totalProgress || 0);
  }

  getCostCenterMachine(
    costCenter: WorkOrderCostCenterFragment,
    machine: WorkOrderMachine
  ) {
    return costCenter.machines.find(
      (ccm) => ccm.machineId === machine.machine.id
    );
  }

  initMachineMetrics() {
    this.metrics.initMetricFields();

    if (!this.builder.isMachinery) return;

    this.progress.initTotalProgress(this.get());
  }

  getCostCenterMachineIndex(
    costCenter: WorkOrderCostCenterFragment,
    machine: WorkOrderMachine
  ): number {
    return costCenter.machines.findIndex(
      (ccm) => ccm.machineId === machine.machine.id
    );
  }

  onRemove(machine: WorkOrderMachine) {
    super.onRemove(machine);

    const costCenters = this.getCostCenters();
    costCenters.forEach(({ machines }) => {
      const index = machines.findIndex(
        (m) => m.machineId === machine.machine.id
      );
      if (index >= 0) machines.splice(index, 1);
    });

    this.progress.onWorkerCountChanged();
  }
}

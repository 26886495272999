import {
  Row,
  Col,
  Divider,
  Input,
  Typography,
  FormInstance,
  Select,
} from "antd";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import { Form, SelectField, Rules, InputNumber } from "../form";
import { WarehouseSelect } from "../warehouses";
import {
  InventoryOrderStatus,
  PaymentMethodType,
  InventoryOrderChanges,
  InventoryOrderKind,
  CommentableType,
  InventoryOrderDetailsFragment,
  InventoryOrderLineItemFragment,
  StockVariantFragment,
} from "../../lib/graphql";
import {
  SaveIDFunction,
  usePaymentMethodOptions,
  useCurrentUser,
  Destroy,
  useCurrencyOptions,
} from "../../lib/hooks";
import { StockVariantTableInput } from "./StockVariantTableInput";
import {
  formatMoneyAmount,
  shouldUpdate,
  formatVariantLink,
  shouldUpdateBy,
  BLANK_SYMBOL,
  formatPercent,
} from "../../lib/formats";
import {
  BottomPanel,
  ColumnsType,
  CommentList,
  ExchangeRateInput,
  OrderSummary,
} from "../shared";
import { QuantityFormItem } from "./QuantityFormItem";
import { routerPush } from "../../lib/routes";
import { CounterpartySelect } from "../counterparties";
import { AccountSelect, TaxPlanComponentValue } from "../finance";
import DocumentDateFormInput from "../finance/DocumentDateFormInput";
import { filterColumns } from "../../lib/utils";
import { groupBy, sumBy } from "lodash";
import { ReactNode, useState } from "react";
import { useUpdateEffect } from "react-use";

const messages = defineMessages({
  date: {
    id: "documentDate",
    defaultMessage: "documentDate",
  },
  basicInfo: { id: "basicInfo", defaultMessage: "basicInfo" },
  itemVariants: { id: "itemVariants", defaultMessage: "itemVariants" },
  exchangeRate: { id: "exchangeRate", defaultMessage: "exchangeRate" },
  exchangeRateHint: { id: "exchangeRate.editHint", defaultMessage: "editHint" },
  internalId: { id: "internalId", defaultMessage: "internalId" },
  notes: { id: "notes", defaultMessage: "notes" },
});

export const orderKindMessages = {
  purchase: defineMessages({
    entityName: {
      id: "inventoryPurchaseOrders.entityName",
      defaultMessage: "Purchase Order",
    },
    warehouse: {
      id: "inventoryPurchaseOrders.targetWarehouse",
      defaultMessage: "targetWarehouse",
    },
    currency: {
      id: "inventoryPurchaseOrders.currency",
      defaultMessage: "currency",
    },
    details: {
      id: "inventoryPurchaseOrders.details",
      defaultMessage: "purchaseDetails",
    },
    counterparty: {
      id: "inventoryPurchaseOrders.counterparty",
      defaultMessage: "supplier",
    },
    qty: {
      id: "inventoryPurchaseOrders.qty",
      defaultMessage: "Purchase QTY",
    },
    cost: {
      id: "inventoryPurchaseOrders.unitCost",
      defaultMessage: "Unit Cost",
    },
    complete: {
      id: "inventoryPurchaseOrders.complete",
      defaultMessage: "Complete Purchase",
    },
  }),
  sale: defineMessages({
    entityName: {
      id: "inventorySaleOrders.entityName",
      defaultMessage: "Sale Order",
    },
    warehouse: {
      id: "inventorySaleeOrders.targetWarehouse",
      defaultMessage: "Origin Warehouse",
    },
    currency: {
      id: "inventorySaleOrders.currency",
      defaultMessage: "Sale Currency",
    },
    details: {
      id: "inventorySaleOrders.details",
      defaultMessage: "Sale Details",
    },
    counterparty: {
      id: "inventorySaleOrders.counterparty",
      defaultMessage: "Client",
    },
    qty: {
      id: "inventorySaleOrders.qty",
      defaultMessage: "QTY to Sell",
    },
    cost: {
      id: "inventorySaleOrders.unitCost",
      defaultMessage: "Unit Price",
    },
    complete: {
      id: "inventorySaleOrders.complete",
      defaultMessage: "Complete Sale",
    },
  }),
};

export interface OrderLineItemValue extends StockVariantFragment {
  quantity?: number;
  unitCost?: number;
  lineItemId?: string;
  discountRate?: number;
  discount?: number;
  item: InventoryOrderLineItemFragment["variant"]["item"];
  taxPlanValues: InventoryOrderLineItemFragment["taxPlanValues"];
}

export interface InventoryOrderValues extends InventoryOrderChanges {
  id?: string;
  localityId?: string;
  lineItems: Array<Destroy<OrderLineItemValue>>;
  taxValues: InventoryOrderDetailsFragment["taxValues"];
  status: InventoryOrderStatus;
  counterparty?: Pick<InventoryOrderDetailsFragment["counterparty"], "taxPlan">;
}

export interface InventoryOrderFormProps {
  values: InventoryOrderValues;
  onSave(values: InventoryOrderValues): SaveIDFunction;
  orderUrl: string;
}

export function InventoryOrderForm({
  values,
  onSave,
  orderUrl,
}: InventoryOrderFormProps) {
  const intl = useIntl();
  const { paymentMethodOptions, paymentMethods } = usePaymentMethodOptions();
  const { currentTenant } = useCurrentUser();
  const [form] = Form.useForm();
  const [commentForm] = Form.useForm();
  const taxPlanEnabled = !!currentTenant.taxPlanEnabled;

  const {
    load: loadCurrencies,
    loading: loadingCurrencies,
    options: currencyOptions,
    currencies,
  } = useCurrencyOptions();

  const isPaymentMethodDebit = () => {
    const paymentMethodId = form.getFieldValue("paymentMethodId");
    return (
      paymentMethods.find((m) => m.id == paymentMethodId)?.paymentMethodType ===
      PaymentMethodType.Debit
    );
  };

  const kindMessages =
    orderKindMessages[values.kind || InventoryOrderKind.Purchase];

  const isSale = values.kind == InventoryOrderKind.Sale;

  return (
    <>
      <Form
        preventLeaving
        layout="vertical"
        form={form}
        initialValues={values}
        onSubmit={(values, { setSubmitting, showErrors }) => {
          const promise = onSave(values);
          if (!promise) return;

          promise.then((order) => {
            if (!order) return;

            if (order.result) {
              commentForm.setFieldsValue({ commentableId: order.result.id });
              commentForm.submit();

              routerPush(`${orderUrl}/${order.result.id}`);
            } else if (order.errors) {
              showErrors(order.errors);
              setSubmitting(false);
            }
          });
        }}
      >
        <Typography.Title level={4}>
          {intl.formatMessage(messages.basicInfo)}
        </Typography.Title>
        <Row gutter={32}>
          <Col xs={24} md={6}>
            <DocumentDateFormInput
              required
              dateProps={{ defaultTime: "startOfDay" }}
            />
          </Col>

          <Col xs={24} md={6}>
            <Form.Item
              name="warehouseId"
              label={intl.formatMessage(kindMessages.warehouse)}
              rules={[Rules.required]}
              required
            >
              <WarehouseSelect />
            </Form.Item>
          </Col>

          <Col xs={24} md={6}>
            <Form.Item
              name="counterpartyId"
              label={intl.formatMessage(kindMessages.counterparty)}
              rules={[Rules.required]}
            >
              <CounterpartySelect
                allowAddNew
                placeholder={<FormattedMessage id="select.supplier" />}
                optionsHookParams={{
                  variables: { fetchTaxPlan: taxPlanEnabled },
                }}
                onChangeCounterparty={(counterparty) => {
                  if (!counterparty) return;

                  if (counterparty.vendorPaymentMethod) {
                    form.setFields([
                      {
                        name: "paymentMethodId",
                        value: counterparty.vendorPaymentMethod.id,
                      },
                    ]);
                  }

                  form.setFieldValue("counterparty", counterparty);

                  if (!taxPlanEnabled) {
                    form.setFieldValue(
                      "taxRate",
                      (counterparty.taxRate || 0) * 100.0
                    );
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item
              name="internalId"
              label={<FormattedMessage id="documentNumber" />}
            >
              <Input name="internalId" />
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item
              name="currencyCode"
              label={intl.formatMessage(kindMessages.currency)}
              required
              rules={[Rules.required]}
            >
              <SelectField
                optionsHook={() => ({ load: loadCurrencies })}
                options={currencyOptions}
                loading={loadingCurrencies}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item
              noStyle
              shouldUpdate={shouldUpdate("currencyCode", "documentDate")}
            >
              {({ getFieldValue }) => (
                <ExchangeRateInput
                  name="exchangeRate"
                  from={currencies[getFieldValue("currencyCode")]}
                  to={currentTenant.currency}
                  date={getFieldValue("documentDate")}
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item
              name="paymentMethodId"
              label={<FormattedMessage id="paymentMethods.entityName" />}
              rules={[Rules.required]}
            >
              <SelectField
                options={paymentMethodOptions}
                showGroups
                onChange={() =>
                  form.setFields([{ name: "accountId", value: null }])
                }
              />
            </Form.Item>
          </Col>
          {!currentTenant.financeEntriesDisabled && (
            <Col xs={24} md={6}>
              <Form.Item noStyle shouldUpdate={shouldUpdate("paymentMethodId")}>
                {() =>
                  isPaymentMethodDebit() && (
                    <Form.Item
                      name="accountId"
                      label={<FormattedMessage id="payments.account" />}
                      rules={[Rules.required]}
                    >
                      <AccountSelect
                        placeholder={<FormattedMessage id="select.account" />}
                        mode="ledger"
                        kindKey={["cash"]}
                      />
                    </Form.Item>
                  )
                }
              </Form.Item>
            </Col>
          )}
        </Row>
        <Divider dashed />
        <Typography.Title level={4}>
          {intl.formatMessage(kindMessages.details)}
        </Typography.Title>
        <Form.Item
          noStyle
          shouldUpdate={shouldUpdate(
            "currencyCode",
            "warehouseId",
            "documentDate",
            "counterparty"
          )}
        >
          {({ getFieldValue }) => {
            if (!getFieldValue("documentDate") || !getFieldValue("warehouseId"))
              return (
                <Typography.Text type="secondary">
                  <FormattedMessage
                    id="stockTransfers.selectRequiredEntitiesHint"
                    defaultMessage="Select document date and warehouse to choose items"
                  />
                </Typography.Text>
              );

            const warehouseId = getFieldValue("warehouseId");
            const currencyCode = getFieldValue("currencyCode");
            const currency = currencies[currencyCode];
            const documentDate = getFieldValue("documentDate");
            const counterparty: InventoryOrderValues["counterparty"] =
              getFieldValue("counterparty");

            return (
              <>
                <Form.Item
                  noStyle
                  shouldUpdate={shouldUpdateBy(
                    (s) => s.lineItems.filter(Form.undestroyed).length
                  )}
                >
                  {() => (
                    <StockVariantTableInput
                      name="lineItems"
                      dataSource={values.lineItems}
                      warehouseId={warehouseId}
                      disabled={!currency}
                      variantFields={(variant) => {
                        return {
                          unitCost: variant.cost,
                          currencySymbol: currency?.symbol,
                          currencyCode: currency?.isoCode,
                          taxPlanValues: [],
                        };
                      }}
                      optionsHookVariables={{
                        date: documentDate,
                        fetchTaxPlan: taxPlanEnabled,
                      }}
                      withStockOnly={isSale}
                      markAsDestroyed={(v) => !!v.lineItemId}
                      columns={(variants) => {
                        const columns: ColumnsType<OrderLineItemValue> =
                          filterColumns([
                            {
                              title: (
                                <FormattedMessage
                                  id="variants.entityName"
                                  defaultMessage="variant"
                                />
                              ),
                              width: "24rem",
                              dataIndex: "name",
                              render: (_, v) => formatVariantLink(v),
                            },
                            {
                              title: intl.formatMessage(kindMessages.qty),
                              width: "24rem",
                              render: (_, v, index) => (
                                <QuantityFormItem
                                  name={["lineItems", index, "quantity"]}
                                  variant={v}
                                  rules={[Rules.gtZero]}
                                />
                              ),
                            },
                            {
                              title: intl.formatMessage(kindMessages.cost),
                              width: "12rem",
                              render: (_, _v, index) => {
                                return (
                                  <Form.Item
                                    name={["lineItems", index, "unitCost"]}
                                    rules={[Rules.gtEqZero]}
                                    required
                                    compact
                                  >
                                    <InputNumber
                                      step={0.1}
                                      addonBefore={currency && currency.symbol}
                                    />
                                  </Form.Item>
                                );
                              },
                            },
                            {
                              title: (
                                <FormattedMessage
                                  id="discount.unit"
                                  defaultMessage="Unit discount"
                                />
                              ),
                              width: "11rem",
                              render: (_, _v, index) => {
                                const discountRateName = [
                                  "lineItems",
                                  index,
                                  "discountRate",
                                ];

                                const discountName = [
                                  "lineItems",
                                  index,
                                  "discount",
                                ];

                                const quantityName = [
                                  "lineItems",
                                  index,
                                  "quantity",
                                ];

                                const unitCostName = [
                                  "lineItems",
                                  index,
                                  "unitCost",
                                ];

                                return (
                                  <Form.Item
                                    noStyle
                                    shouldUpdate={shouldUpdate(
                                      discountRateName,
                                      discountName,
                                      quantityName,
                                      unitCostName
                                    )}
                                  >
                                    {({ getFieldValue, setFieldValue }) => {
                                      const isRate =
                                        getFieldValue(discountName) == null;
                                      const fieldName = isRate
                                        ? "discountRate"
                                        : "discount";
                                      const name = [
                                        "lineItems",
                                        index,
                                        fieldName,
                                      ];
                                      const quantity =
                                        getFieldValue(quantityName) || 0;
                                      const unitCost =
                                        getFieldValue(unitCostName) || 0;

                                      const subtotal = quantity * unitCost;
                                      const discount = getFieldValue(name) || 0;

                                      return (
                                        <Form.Item
                                          name={name}
                                          compact
                                          extra={
                                            <Typography.Text
                                              type="secondary"
                                              style={{
                                                position: "absolute",
                                                fontSize: 12,
                                              }}
                                            >
                                              <FormattedMessage
                                                id="discount"
                                                defaultMessage="Discount"
                                              />
                                              :{" "}
                                              {isRate
                                                ? formatMoneyAmount(
                                                    (discount * subtotal) / 100,
                                                    currencyCode
                                                  )
                                                : formatPercent(
                                                    discount / subtotal
                                                  )}
                                            </Typography.Text>
                                          }
                                        >
                                          <InputNumber
                                            step={1}
                                            min={0}
                                            max={isRate ? 100 : undefined}
                                            addonBefore={
                                              <Select
                                                defaultValue={fieldName}
                                                options={[
                                                  {
                                                    value: "discountRate",
                                                    label: "%",
                                                  },
                                                  {
                                                    value: "discount",
                                                    label:
                                                      currency &&
                                                      currency.symbol,
                                                  },
                                                ]}
                                                onChange={(value) => {
                                                  setFieldValue(
                                                    discountRateName,
                                                    value === "discountRate"
                                                      ? 0
                                                      : null
                                                  );
                                                  setFieldValue(
                                                    discountName,
                                                    value === "discountRate"
                                                      ? null
                                                      : 0
                                                  );
                                                }}
                                              />
                                            }
                                          />
                                        </Form.Item>
                                      );
                                    }}
                                  </Form.Item>
                                );
                              },
                            },
                            {
                              title: <FormattedMessage id="subtotal" />,
                              width: "8rem",
                              render: (_, _v, index) => (
                                <SubtotalComponent
                                  index={index}
                                  render={(subtotal) =>
                                    currency &&
                                    formatMoneyAmount(
                                      subtotal,
                                      currency.isoCode
                                    )
                                  }
                                />
                              ),
                            },
                          ]);

                        if (taxPlanEnabled) {
                          variants
                            .filter(Form.undestroyed)
                            .flatMap((v) => v.item.defaultTaxPlan?.components)
                            .concat(counterparty?.taxPlan?.components)
                            .forEach((tc) => {
                              if (!tc) return;

                              const key = `tc-${tc.taxComponent.abbr}`;
                              if (columns.find((c) => c.key === key)) return;

                              columns.push({
                                key,
                                title: tc.taxComponent.name,
                                width: "8rem",
                                render: (_, v, index) => (
                                  <Form.Item
                                    shouldUpdate={shouldUpdate("counterparty")}
                                    noStyle
                                  >
                                    {() => {
                                      if (!currency) return BLANK_SYMBOL;

                                      if (
                                        v.lineItemId &&
                                        v.taxPlanValues.length &&
                                        !v.taxPlanValues.find(
                                          (v) =>
                                            v.component.id ===
                                            tc.taxComponent.id
                                        )
                                      ) {
                                        return BLANK_SYMBOL;
                                      }

                                      return (
                                        <TaxPlanComponent
                                          index={index}
                                          form={form}
                                          currencyCode={currency.isoCode}
                                          taxPlanId={
                                            v.item.defaultTaxPlan?.id ||
                                            counterparty?.taxPlan?.id
                                          }
                                          taxComponentId={tc.taxComponent.id}
                                          defaultItem={values.lineItems.find(
                                            (li) => li.id === v.id
                                          )}
                                        />
                                      );
                                    }}
                                  </Form.Item>
                                ),
                              });
                            });
                        }

                        return columns;
                      }}
                    />
                  )}
                </Form.Item>

                <br />

                <Row justify="space-between" gutter={[16, 16]}>
                  <Col xs={24} md={6} lg={7} xl={5}></Col>
                  <Col xs={24} md={16} lg={12}>
                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldsValue }) => {
                        const order = getFieldsValue(
                          true
                        ) as InventoryOrderDetailsFragment;

                        const subtotal = order.lineItems.reduce((sum, item) => {
                          if (!item.unitCost || !item.quantity) return sum;
                          return (
                            sum +
                            item.unitCost *
                              item.quantity *
                              (1 - (item.discountRate || 0) / 100.0) -
                            (item.discount || 0)
                          );
                        }, 0);

                        if (currency) order.currency = currency;

                        const taxValues = Object.entries(
                          groupBy(
                            order.lineItems.flatMap((li) => li.taxPlanValues),
                            (li) => li.component.id
                          )
                        ).map(([, values]) => ({
                          component: values[0].component,
                          value: sumBy(values, "value"),
                        }));

                        return (
                          <OrderSummary
                            order={order}
                            subtotal={subtotal}
                            taxValues={taxValues}
                            otherCosts={order.otherCosts || 0}
                          />
                        );
                      }}
                    </Form.Item>
                  </Col>
                </Row>
              </>
            );
          }}
        </Form.Item>
        <Divider dashed />
        <Form.Item name="notes" label={intl.formatMessage(messages.notes)}>
          <Input.TextArea rows={5} />
        </Form.Item>

        <BottomPanel
          sticky
          buttons={[
            BottomPanel.CancelButton({ route: orderUrl }),
            BottomPanel.SubmitButton(),
          ]}
        />
      </Form>

      <CommentList
        form={commentForm}
        commentableType={CommentableType.InventoryOrder}
        commentableId={values.id}
      />
    </>
  );
}

function SubtotalComponent({
  index,
  render,
}: {
  index: number;
  render: (subtotal: number, quantity: number, unitCost: number) => ReactNode;
}) {
  return (
    <Form.Item
      noStyle
      shouldUpdate={shouldUpdate(
        ["lineItems", index, "quantity"],
        ["lineItems", index, "unitCost"],
        ["lineItems", index, "discount"],
        ["lineItems", index, "discountRate"]
      )}
    >
      {({ getFieldValue }) => {
        const quantity = getFieldValue(["lineItems", index, "quantity"]) || 0;
        const unitCost = getFieldValue(["lineItems", index, "unitCost"]) || 0;
        const discountRate =
          getFieldValue(["lineItems", index, "discountRate"]) || 0;
        const discount = getFieldValue(["lineItems", index, "discount"]) || 0;

        if (!quantity || !unitCost) return;

        const subtotal =
          quantity * unitCost * (1 - discountRate / 100.0) - discount;
        return render(subtotal, quantity, unitCost);
      }}
    </Form.Item>
  );
}

function TaxPlanComponent({
  index,
  taxPlanId,
  taxComponentId,
  currencyCode,
  defaultItem,
  form,
}: {
  index: number;
  taxPlanId?: string;
  taxComponentId: string;
  currencyCode: string;
  defaultItem?: OrderLineItemValue;
  form: FormInstance;
}) {
  const [showDefaultValue, setShowDefaultValue] = useState(true);

  // update when user changes counterparty w/o tax plan
  useUpdateEffect(() => {
    if (!taxPlanId) {
      form.setFieldValue(["lineItems", index, "taxPlanValues"], []);
    }
    setShowDefaultValue(false);
  }, [taxPlanId]);

  if (!taxPlanId) return <>{BLANK_SYMBOL}</>;

  return (
    <SubtotalComponent
      index={index}
      render={(subtotal, quantity, unitCost) => {
        if (
          showDefaultValue &&
          defaultItem &&
          defaultItem.quantity === quantity &&
          defaultItem.unitCost === unitCost &&
          defaultItem.taxPlanValues.length
        ) {
          const defaultValue = defaultItem.taxPlanValues.find(
            (v) => v.component.id == taxComponentId
          )?.value;
          return formatMoneyAmount(defaultValue, currencyCode);
        }

        return (
          <TaxPlanComponentValue
            taxPlanId={taxPlanId}
            subtotal={subtotal}
            quantity={quantity}
            currencyCode={currencyCode}
            taxComponentId={taxComponentId}
            onChange={(value) => {
              form.setFieldValue(["lineItems", index, "taxPlanValues"], value);
              setShowDefaultValue(false);
            }}
          />
        );
      }}
    />
  );
}

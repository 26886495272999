import { FormattedMessage } from "react-intl";
import { TableInput } from "../../shared";
import {
  ItemKind,
  InventoryStatus,
  WorkOrderVariantTokenFragment,
} from "../../../lib/graphql";
import {
  formatUnitValue,
  formatUnitPerDH,
  formatNumber,
  shouldUpdate,
  formatVariantLink,
} from "../../../lib/formats";
import { ReactNode, useContext, useState } from "react";
import { useStockVariantOptions } from "../../../lib/hooks/inventory/variants";
import { Form, InputNumber, RuleBuilder, Rules } from "../../form";
import { Button, Col, Row, Tag } from "antd";
import { WorkOrderContext } from "./WorkOrderContext";
import Table, { ColumnsType } from "antd/lib/table";
import { EditOutlined } from "@ant-design/icons";
import { useCurrentUser, useItemSidebarContext } from "../../../lib/hooks";
import {
  EditInventoryRequestSidebar,
  InventoryRequestWarehouseCell,
} from "./inventory";

function shouldUpdateOnTokenChange(index: number) {
  return (prev: any, next: any) => prev.tokens[index] != next.tokens[index];
}

function WorkerCountNorm({ readonly }: { readonly: boolean }) {
  const { workOrder, builder } = useContext(WorkOrderContext);

  const progressUnit = workOrder.activity.progressUnit;

  return (
    <Row gutter={16}>
      <Col>
        <Form.Item
          name="workerCount"
          label={
            <FormattedMessage
              id="workOrders.workerCount"
              defaultMessage="workerCount"
            />
          }
        >
          {readonly ? (
            formatNumber(workOrder.workerCount || 0)
          ) : (
            <InputNumber min={0} step={1} precision={0} />
          )}
        </Form.Item>
      </Col>
      {!readonly && (
        <Col>
          <Form.Item
            name="estimatedNorm"
            label={
              <FormattedMessage
                id="workOrders.estimatedNorm"
                defaultMessage="estimatedNorm"
              />
            }
          >
            <InputNumber min={0} addonAfter={formatUnitPerDH(progressUnit)} />
          </Form.Item>
        </Col>
      )}
      {!readonly && (
        <Col>
          <Form.Item
            label={
              <FormattedMessage
                id="workOrders.estimatedAmount"
                defaultMessage="estimatedAmount"
              />
            }
            shouldUpdate={(prev, next) =>
              prev.workerCount != next.workerCount ||
              prev.estimatedNorm != next.estimatedNorm
            }
          >
            {() =>
              formatUnitValue(builder.tokens.estimatedTokens, progressUnit)
            }
          </Form.Item>
        </Col>
      )}
    </Row>
  );
}

export function WorkOrderTokens({
  readonly,
  inProgress,
}: {
  readonly: boolean;
  inProgress: boolean;
}) {
  const { workOrder, builder } = useContext(WorkOrderContext);
  const { currentTenant } = useCurrentUser();
  const tokenUnit = workOrder.activity.progressUnit;
  const [editIndex, setEditIndex] = useState(0);
  const { setCurrentAction } = useItemSidebarContext();

  const formatTokenVariant = (content: ReactNode, opt: any) => (
    <>
      {content}{" "}
      <Tag color="gold">
        {formatUnitValue(opt.variant.token.quantity, tokenUnit)}
      </Tag>
    </>
  );

  const columns: ColumnsType<WorkOrderVariantTokenFragment> = [
    {
      title: <FormattedMessage id="variants.entityName" />,
      dataIndex: ["variant", "id"],
      render: (_, v) => formatVariantLink(v.variant),
    },
    {
      title: (
        <FormattedMessage
          id="tokens.denomination"
          defaultMessage="denomination"
        />
      ),
      render: (_, v) => formatUnitValue(v.variant.token?.quantity, tokenUnit),
    },
    {
      title: (
        <FormattedMessage
          id="variants.qtyRequested"
          defaultMessage="qtyRequested"
        />
      ),
      width: 200,
      align: "center",
      render: (_, v, index) =>
        v.status == InventoryStatus.NotRequested ? (
          <Form.Item
            compact
            name={["tokens", index, "totalAmount"]}
            rules={[Rules.gtZero]}
          >
            <InputNumber
              step={1}
              precision={0}
              min={0}
              addonAfter={v.unit.abbr}
              onChange={() => builder.tokens.onTokenChanged()}
            />
          </Form.Item>
        ) : (
          <>
            {formatUnitValue(v.totalAmount, v.unit)}
            {!readonly && (
              <Button
                icon={<EditOutlined />}
                onClick={() => {
                  setEditIndex(index);
                  setCurrentAction("editToken");
                }}
                style={{
                  position: "absolute",
                  right: "20px",
                  marginTop: "-5px",
                }}
              />
            )}
          </>
        ),
    },
    {
      title: inProgress ? (
        <FormattedMessage
          id="workOrders.totalHarvested"
          defaultMessage="totalHarvested"
        />
      ) : (
        <FormattedMessage
          id="tokens.totalToHarvest"
          defaultMessage="totalToHarvest"
        />
      ),
      width: "12rem",
      render: (_, _v, index) => (
        <Form.Item noStyle shouldUpdate={shouldUpdateOnTokenChange(index)}>
          {() =>
            formatUnitValue(builder.tokens.getTotalToHarvest(index), tokenUnit)
          }
        </Form.Item>
      ),
    },
  ];

  if (!currentTenant.inventoryRequestDisabled || inProgress) {
    columns.push({
      title: (
        <FormattedMessage
          id={
            currentTenant.inventoryRequestDisabled
              ? "inventoryRequests.sourceWarehouse"
              : "workOrders.inventoryStatus"
          }
        />
      ),
      width: "15rem",
      align: "center",
      dataIndex: "status",
      render: (_, _v, index) => (
        <InventoryRequestWarehouseCell name={["tokens", index]} />
      ),
    });
  }

  if (inProgress) {
    columns.splice(
      3,
      0,
      {
        title: (
          <FormattedMessage
            id="variants.qtyToReturn"
            defaultMessage="qtyToReturn"
          />
        ),
        dataIndex: "returnedAmount",
        width: 200,
        render: (_, v, index) =>
          readonly ? (
            formatUnitValue(v.returnedAmount || 0, v.unit)
          ) : (
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev.tokens[index].totalAmount != next.tokens[index].totalAmount
              }
            >
              {({ getFieldValue }) => (
                <Form.Item
                  compact
                  name={["tokens", index, "returnedAmount"]}
                  rules={[
                    Rules.gtEqZero,
                    RuleBuilder.ltEq(
                      getFieldValue(["tokens", index, "totalAmount"])
                    ),
                  ]}
                >
                  <InputNumber
                    step={1}
                    precision={0}
                    min={0}
                    addonAfter={v.unit.abbr}
                    onChange={() => builder.tokens.onTokenChanged()}
                  />
                </Form.Item>
              )}
            </Form.Item>
          ),
      },
      {
        title: (
          <FormattedMessage
            id="workOrders.tokens.paid"
            defaultMessage="paidTokens"
          />
        ),
        align: "center",
        render: (_, v, index) => (
          <Form.Item shouldUpdate={shouldUpdateOnTokenChange(index)} noStyle>
            {() => formatUnitValue(builder.tokens.paidAmount(index), v.unit)}
          </Form.Item>
        ),
      }
    );
  }

  if (!readonly) {
    builder.tokens.init();
  }
  return (
    <>
      <EditInventoryRequestSidebar
        actionName="editToken"
        variant={builder.tokens.getBy(editIndex)}
        onChanged={(value) => {
          builder.form.setFieldValue(["tokens", editIndex], value);
        }}
      />
      <WorkerCountNorm readonly={readonly} />
      <TableInput
        name="tokens"
        tableProps={{
          bordered: true,
          summary: (tokens) => {
            if (tokens.length === 0) return;

            return (
              <Form.Item noStyle shouldUpdate={shouldUpdate("tokens")}>
                {() => (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={inProgress ? 5 : 2}>
                      <strong>
                        <FormattedMessage id="total" />
                      </strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="center">
                      <strong>
                        {formatUnitValue(
                          builder.tokens.totalUnits(),
                          tokens[0].unit
                        )}
                      </strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} colSpan={readonly ? 2 : 3}>
                      <strong>
                        {formatUnitValue(
                          builder.tokens.totalHarvested(),
                          tokenUnit
                        )}
                      </strong>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                )}
              </Form.Item>
            );
          },
        }}
        dataSource={workOrder.tokens}
        rowKey={(f) => f.variant.id}
        disabled={readonly}
        tableSelectProps={{
          mode: "multiple",
          optionsHook: useStockVariantOptions,
          optionsHookParams: {
            variables: {
              localityId: workOrder.locality.id,
              date: workOrder.documentDate,
              token: true,
              filter: { itemKind: [ItemKind.Reusable], token: true },
            },
          },
          formatOption: formatTokenVariant,
          labelRenderer: formatTokenVariant,
          placeholder: (
            <FormattedMessage id="select.variants" defaultMessage="variants" />
          ),
          entityById: (_, { variant }) => {
            if (variant) {
              return {
                id: "",
                variant,
                totalAmount: 0,
                status: InventoryStatus.NotRequested,
                unit: variant.variationUnit,
              };
            }
          },
        }}
        addSorter={(a, b) => builder.tokens.sorter(a, b)}
        allowBulkRemove
        columns={columns}
      />
    </>
  );
}

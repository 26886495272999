import { Row, Col, Divider, Typography, Input } from "antd";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import { SelectField, Rules, Form, RuleBuilder } from "../form";
import { WarehouseSelect, WarehouseSelectAll } from "../warehouses";
import {
  TransferStatus,
  TransferChanges,
  AdjustmentTypeFragment,
  StockVariantFragment,
  CommentableType,
} from "../../lib/graphql";
import { SaveIDFunction, useAdjustmentTypeOptions } from "../../lib/hooks";
import {
  StockVariantTableInput,
  StockVariantTableInputProps,
} from "./StockVariantTableInput";
import Router from "next/router";
import DocumentDateFormInput from "../finance/DocumentDateFormInput";
import { roundUnit, shouldUpdate } from "../../lib/formats";
import { BottomPanel, CommentList } from "../shared";
import { useForm } from "antd/lib/form/Form";

const messages = defineMessages({
  warehouse: {
    id: "stockTransfers.sourceWarehouse",
    defaultMessage: "sourceWarehouse",
  },
  targetWarehouse: {
    id: "stockTransfers.targetWarehouse",
    defaultMessage: "targetWarehouse",
  },
  wrongTargetWarehouse: {
    id: "stockTransfers.wrongTargetWarehouse",
    defaultMessage: "wrongTargetWarehouse",
  },
  basicInfo: {
    id: "stockTransfers.timeLocation",
    defaultMessage: "timeAndLocation",
  },
  internalId: { id: "internalId", defaultMessage: "internalId" },
  notes: { id: "notes", defaultMessage: "notes" },
});

export interface VariantTransferValue extends StockVariantFragment {
  quantity?: number;
  transferVariantId?: string;
  newCost?: number;
  _destroy?: boolean;
}

export interface TransferValues
  extends Omit<TransferChanges, "transferVariantsAttributes"> {
  id?: string;
  sourceLocalityId?: string;
  targetLocalityId?: string;
  adjustmentTypeId?: string;
  variants: Array<VariantTransferValue>;
}

export interface TransferFormProps {
  entityName: string;
  values: TransferValues;
  onSave(values: TransferValues): SaveIDFunction;
  transferUrl: string;
  showAdjustmentType?: boolean;
  showTargetWarehouse?: boolean;
  variantTableProps?(
    type?: AdjustmentTypeFragment,
    value?: TransferValues
  ): Omit<
    StockVariantTableInputProps<VariantTransferValue>,
    "name" | "dataSource"
  >;
}

export function TransferForm({
  entityName,
  values,
  onSave,
  transferUrl,
  showAdjustmentType,
  showTargetWarehouse,
  variantTableProps,
}: TransferFormProps) {
  const intl = useIntl();
  const { adjustmentTypeOptions, adjustmentTypes } = useAdjustmentTypeOptions();
  const disabled = values.status !== TransferStatus.Open;

  const [commentForm] = useForm();

  return (
    <>
      <Form
        preventLeaving
        layout="vertical"
        initialValues={values}
        onSubmit={(values, { setSubmitting, showErrors }) => {
          if (!values.documentDate || !values.sourceWarehouseId) return;

          const promise = onSave(values);
          if (!promise) return;

          promise
            .then((transfer) => {
              if (!transfer) return;

              if (transfer.result) {
                commentForm.setFieldsValue({
                  commentableId: transfer.result.id,
                });
                commentForm.submit();

                Router.push(
                  `${transferUrl}/[id]`,
                  `${transferUrl}/${transfer.result.id}`
                );
              } else if (transfer.errors) {
                showErrors(transfer.errors);
                setSubmitting(false);
              }
            })
            .catch(() => setSubmitting(false));
        }}
      >
        <Typography.Title level={4}>
          {intl.formatMessage(messages.basicInfo)}
        </Typography.Title>
        <Row gutter={32}>
          <Col xs={24} lg={5}>
            <DocumentDateFormInput
              required
              disabled={disabled}
              dateProps={{ defaultTime: "startOfDay" }}
            />
          </Col>

          {showAdjustmentType && (
            <Col lg={4} xs={24}>
              <Form.Item
                name="adjustmentTypeId"
                label={<FormattedMessage id="stockAdjustments.type" />}
                rules={[Rules.required]}
                required
              >
                <SelectField
                  showGroups
                  options={adjustmentTypeOptions}
                  disabled={disabled || !!values.id}
                />
              </Form.Item>
            </Col>
          )}

          <Col lg={5} xs={24}>
            <Form.Item
              name="sourceWarehouseId"
              label={intl.formatMessage(messages.warehouse)}
              rules={[Rules.required]}
              required
            >
              <WarehouseSelect
                disabled={disabled || values.variants.length > 0}
              />
            </Form.Item>
          </Col>

          {showTargetWarehouse && (
            <Col lg={5} xs={24}>
              <Form.Item
                name="targetWarehouseId"
                label={intl.formatMessage(messages.targetWarehouse)}
                rules={[
                  Rules.required,
                  {
                    validator: (_rule, val) => {
                      if (val === values.sourceWarehouseId) {
                        return Promise.reject(
                          intl.formatMessage(messages.wrongTargetWarehouse)
                        );
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
                required
              >
                <WarehouseSelectAll
                  skipLocalityScope
                  defaultValue={
                    values.targetLocalityId && values.targetWarehouseId
                      ? [values.targetLocalityId, values.targetWarehouseId]
                      : undefined
                  }
                  disabled={disabled}
                />
              </Form.Item>
            </Col>
          )}

          <Col lg={4} xs={24}>
            <Form.Item
              name="internalId"
              label={intl.formatMessage(messages.internalId)}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>

        <Divider dashed />

        <Typography.Title level={4}>
          <FormattedMessage id="details.header" values={{ entityName }} />
        </Typography.Title>

        <Form.Item
          noStyle
          shouldUpdate={shouldUpdate(
            "sourceWarehouseId",
            "adjustmentTypeId",
            "documentDate"
          )}
        >
          {({ getFieldValue, getFieldsValue }) => {
            if (
              !getFieldValue("documentDate") ||
              !getFieldValue("sourceWarehouseId")
            )
              return (
                <Typography.Text type="secondary">
                  <FormattedMessage
                    id="stockTransfers.selectRequiredEntitiesHint"
                    defaultMessage="Select document date and warehouse to choose items"
                  />
                </Typography.Text>
              );

            const adjustmentTypeId = getFieldValue("adjustmentTypeId");
            const type = adjustmentTypes.find((t) => t.id === adjustmentTypeId);

            return (
              <Form.Item label={<FormattedMessage id="itemVariants" />}>
                <StockVariantTableInput
                  name="variants"
                  dataSource={values.variants}
                  warehouseId={getFieldValue("sourceWarehouseId")}
                  disabled={disabled}
                  markAsDestroyed={(v) => !!v.transferVariantId}
                  columns={[]}
                  {...(variantTableProps &&
                    variantTableProps(type, getFieldsValue(true)))}
                />
              </Form.Item>
            );
          }}
        </Form.Item>

        <Divider dashed />

        <Form.Item name="notes" label={intl.formatMessage(messages.notes)}>
          <Input.TextArea name="notes" rows={5} disabled={disabled} />
        </Form.Item>

        <Divider dashed />

        <BottomPanel
          sticky
          buttons={[
            BottomPanel.CancelButton({
              route: transferUrl,
            }),
            !disabled ? BottomPanel.SubmitButton() : undefined,
          ]}
        />
      </Form>

      <CommentList
        form={commentForm}
        commentableType={CommentableType.InventoryTransfer}
        commentableId={values.id}
      />
    </>
  );
}

TransferForm.stockPresence = (index: number) => {
  return [
    Rules.gtZero,
    RuleBuilder.custom((form, value) => {
      const variant = form.getFieldValue([
        "variants",
        index,
      ]) as VariantTransferValue;

      if (
        value &&
        value > roundUnit(variant.stock.onHand / variant.variationValue)
      ) {
        return (
          <FormattedMessage
            id="stock.unsufficient"
            defaultMessage="unsufficientStock"
          />
        );
      }

      return true;
    }),
  ];
};

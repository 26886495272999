import {
  ActivityDetailsFragment,
  ActivityPaymentFragment,
  ActivityPaymentFields,
  ActivityPaymentChanges,
  WorkUnit,
  WorkOrderCategory,
} from "../../../lib/graphql";
import { Typography, Table, Switch, Space } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { tableAddEditDiscardColumn, formatWage } from "../../../lib/formats";
import {
  useItemSidebarContext,
  usePositionOptions,
  useLocalityOptions,
  useActivityPaymentUpdate,
  useCurrentUser,
  useActivityPaymentDiscard,
  useActivityPaymentCreate,
  useWorkUnitFormatters,
} from "../../../lib/hooks";
import { ItemSidebar, DiscardItem, NewForm, EditForm } from "../../shared";
import { useState } from "react";
import { Form, InputNumber, Rules } from "../../form";
import { ItemFieldFormConfig } from "../../shared/ListView/ItemForm";
import { CropSelect } from "../CropSelect";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

function PaymentTable({
  activity,
  wageUnit,
  setCurrentPayment,
  readonly,
}: {
  activity: ActivityDetailsFragment;
  wageUnit: WorkUnit;
  setCurrentPayment: (payment: ActivityPaymentFragment) => void;
  readonly?: boolean;
}) {
  const [showAll, setShowAll] = useState(false);
  const { setCurrentAction } = useItemSidebarContext();
  const { currentTenant } = useCurrentUser();

  const payments = activity.payments.filter(
    (p) => p.wageUnit === wageUnit && (showAll || !p.discardedAt)
  );

  return (
    <div style={{ position: "relative", textAlign: "right" }}>
      <Space style={{ marginBottom: "4px" }}>
        <FormattedMessage id="showDiscarded" />
        <Switch
          unCheckedChildren={<EyeInvisibleOutlined />}
          checkedChildren={<EyeOutlined />}
          onChange={(checked) => setShowAll(checked)}
        />
      </Space>

      <Table
        dataSource={payments}
        pagination={false}
        rowKey="id"
        rowClassName={(v) => (v.discardedAt ? "row-disabled" : "")}
        columns={[
          {
            title: <FormattedMessage id="wage" defaultMessage="wage" />,
            dataIndex: "wage",
            render: (_, p) =>
              formatWage(p, currentTenant.currencyCode, activity.progressUnit),
          },
          {
            title: <FormattedMessage id="positions" />,
            dataIndex: ["position", "name"],
            render: (name) =>
              name || (
                <FormattedMessage
                  id="positions.all"
                  defaultMessage="allPositions"
                />
              ),
          },
          {
            title: <FormattedMessage id="localities" />,
            dataIndex: ["locality", "name"],
            render: (name) =>
              name || (
                <FormattedMessage
                  id="localities.all"
                  defaultMessage="allLocalities"
                />
              ),
          },
          activity.workOrderCategory === WorkOrderCategory.Agricultural
            ? {
                title: <FormattedMessage id="crops" />,
                dataIndex: ["crop", "name"],
                render: (name) =>
                  name || (
                    <FormattedMessage
                      id="crops.all"
                      defaultMessage="allCrops"
                    />
                  ),
              }
            : {},
          ...(readonly
            ? []
            : [
                tableAddEditDiscardColumn<ActivityPaymentFragment>({
                  onNew: () => {
                    setCurrentPayment({
                      wageUnit,
                    } as ActivityPaymentFragment);
                    setCurrentAction("newPayment");
                  },
                  onEdit: (p) => {
                    setCurrentPayment(p);
                    setCurrentAction("editPayment");
                  },
                  onDiscard: (p) => {
                    setCurrentPayment(p);
                    setCurrentAction("discardPayment");
                  },
                  activity,
                  wageUnit,
                }),
              ]),
        ]}
      />
    </div>
  );
}

const messages = defineMessages({
  payment: {
    id: "activities.payment",
    defaultMessage: "payment",
  },
  allPositions: { id: "positions.all", defaultMessage: "allPositions" },
});

interface ActivityPaymentsProps {
  activity: ActivityDetailsFragment;
  readonly?: boolean;
}

export function ActivityPayments({
  activity,
  readonly,
}: ActivityPaymentsProps) {
  const intl = useIntl();
  const entityName = intl.formatMessage(messages.payment);
  const [currentPayment, setCurrentPayment] =
    useState<ActivityPaymentFragment>();
  const { currentTenant } = useCurrentUser();
  const { formatWorkUnit } = useWorkUnitFormatters();

  if (!currentTenant) return null;

  const fields: (
    payment: ActivityPaymentFragment
  ) => ItemFieldFormConfig<ActivityPaymentFields | ActivityPaymentChanges>[] = (
    payment: ActivityPaymentFragment
  ) => [
    {
      label: entityName,
      name: "wage",
      type: "custom",
      rules: [Rules.gtEqZero],
      render: () => (
        <InputNumber
          addonBefore={currentTenant.currency.symbol}
          addonAfter={
            payment.wageUnit === WorkUnit.Piecework
              ? activity.progressUnit.abbr
              : formatWorkUnit(payment.wageUnit)
          }
        />
      ),
    },
    {
      label: <FormattedMessage id="positions.entityName" />,
      name: "positionId",
      type: "select",
      showGroups: true,
      placeholder: intl.formatMessage(messages.allPositions),
      optionsHook: usePositionOptions,
    },
    {
      label: <FormattedMessage id="localities.entityName" />,
      name: "localityId",
      type: "select",
      placeholder: (
        <FormattedMessage id="localities.all" defaultMessage="allLocalities" />
      ),
      optionsHook: useLocalityOptions,
    },
    {
      label: <FormattedMessage id="crops.entityName" />,
      name: "cropId",
      type: "custom",
      hidden: activity.workOrderCategory !== WorkOrderCategory.Agricultural,
      render: () => (
        <CropSelect
          placeholder={
            <FormattedMessage id="crops.all" defaultMessage="allCrops" />
          }
        />
      ),
    },
    {
      type: "custom",
      render: () => (
        <Form.Item key="activityId" name="activityId" compact>
          <span />
        </Form.Item>
      ),
    },
  ];

  return (
    <>
      <Typography.Paragraph type="secondary">
        <FormattedMessage
          id="activities.payment.sectionHint"
          defaultMessage="sectionHint"
        />
      </Typography.Paragraph>

      <ItemSidebar
        item={currentPayment}
        sidebarActions={{
          editPayment: ({ item, closeSidebar }) =>
            item && (
              <EditForm
                item={item}
                entityName={entityName}
                mutation={useActivityPaymentUpdate}
                mutationOptions={{ refetchQueries: ["Activity"] }}
                onMutate={(result) => result.activityPaymentUpdate}
                onSuccess={closeSidebar}
                onCancel={closeSidebar}
                fields={fields(item)}
                formValues={(p) => ({
                  wage: p.wage,
                  wageUnit: p.wageUnit,
                  localityId: p.locality?.id,
                  positionId: p.position?.id,
                  cropId: p.crop?.id,
                })}
              />
            ),
          newPayment: ({ item, closeSidebar }) =>
            item && (
              <NewForm
                entityName={entityName}
                mutation={useActivityPaymentCreate}
                mutationOptions={{ refetchQueries: ["Activity"] }}
                onMutate={(result) => result.activityPaymentCreate}
                onClose={closeSidebar}
                fields={fields(item)}
                formValues={() =>
                  ({
                    wageUnit: item.wageUnit,
                    activityId: activity.id,
                  } as ActivityPaymentFields)
                }
              />
            ),
          discardPayment: ({ item, closeSidebar }) =>
            item && (
              <DiscardItem
                item={item}
                entityName={entityName}
                mutation={useActivityPaymentDiscard}
                onMutate={(result) => result.activityPaymentDiscard}
                mutationOptions={{ refetchQueries: ["ActivityPayments"] }}
                onCancel={closeSidebar}
                onSuccess={closeSidebar}
              />
            ),
        }}
      />

      <br />
      <Typography.Paragraph>
        <FormattedMessage
          id="activities.payment.dailyTitle"
          defaultMessage="dailyTitle"
        />
      </Typography.Paragraph>
      <Typography.Paragraph type="secondary">
        <FormattedMessage
          id="activities.payment.dailyHint"
          defaultMessage="dailyHint"
        />
      </Typography.Paragraph>

      <PaymentTable
        activity={activity}
        wageUnit={WorkUnit.Day}
        setCurrentPayment={setCurrentPayment}
        readonly={readonly}
      />

      <br />
      <br />
      <Typography.Paragraph>
        <FormattedMessage
          id="activities.payment.pieceworkTitle"
          defaultMessage="pieceworkTitle"
        />
      </Typography.Paragraph>
      <Typography.Paragraph type="secondary">
        <FormattedMessage
          id="activities.payment.pieceworkHint"
          defaultMessage="pieceworkHint"
        />
      </Typography.Paragraph>

      <PaymentTable
        activity={activity}
        wageUnit={WorkUnit.Piecework}
        setCurrentPayment={setCurrentPayment}
        readonly={readonly}
      />
    </>
  );
}

import { mean, sumBy } from "lodash";
import { distributeProportionally, roundUnit } from "../../../../lib/formats";
import {
  InventoryStatus,
  WorkOrderCostCenterFragment,
  WorkOrderVariantFragment,
} from "../../../../lib/graphql";
import { WorkOrderToolBuilder, WorkOrderVariant } from "./tools";
import { debounce } from "../../../../lib/utils";

const PRECISION = 10000;

export class WorkOrderInputBuilder extends WorkOrderToolBuilder {
  private requestTotal = false;
  public debouncedCalculateAmountByTotal = debounce(
    this.calculateAmountByTotal
  );

  protected get field() {
    return "inputs";
  }

  getTotalConsumed(variant: WorkOrderVariantFragment) {
    const costCenters = this.getCostCenters();

    return sumBy(
      costCenters,
      (cc) =>
        cc.variants.find((v) => v.variantId === variant.variant.id)
          ?.consumedAmount || 0
    );
  }

  initInputs(requestTotal: boolean) {
    this.requestTotal = requestTotal;

    const costCenters = this.getCostCenters();
    const inputs = this.get();

    inputs.forEach((i) => {
      const dosages: number[] = [];
      costCenters.forEach(({ variants, dayGoal }) => {
        const cfv = variants.find((v) => v.variantId === i.variant.id);

        if (!cfv) {
          variants.push({
            id: "",
            variantId: i.variant.id,
            amount: 0,
            dosage: 0,
          });
        } else if (!cfv.dosage) {
          cfv.dosage = roundUnit(cfv.amount / dayGoal, PRECISION);
        }

        dosages.push(cfv?.dosage || 0);
      });

      if (i.avgDosage == null) {
        i.avgDosage = roundUnit(mean(dosages), PRECISION);
      }
    });
  }

  initInputsConsumption(byProgress: boolean) {
    const inputs = this.get();
    const costCenters = this.getCostCenters();

    inputs.forEach((i) => {
      if (!i.returnedAmount) i.returnedAmount = 0;

      costCenters.forEach((cc) => {
        const ccv = cc.variants.find((v) => v.variantId === i.variant.id);
        if (!ccv) {
          cc.variants.push({
            id: "",
            variantId: i.variant.id,
            amount: 0,
            dosage: 0,
            consumedAmount: 0,
          });
        } else if (ccv.consumedAmount == undefined) {
          ccv.consumedAmount = ccv.amount;
        }
      });

      if (byProgress) this.recalculateByProgress(i);
    });
  }

  isValidConsumption() {
    const inputs = this.get();
    for (const input of inputs) {
      if (
        roundUnit(
          (input.returnedAmount || 0) + this.getTotalConsumed(input),
          PRECISION
        ) != input.totalAmount
      ) {
        return false;
      }
    }
    return true;
  }

  onRemove(input: WorkOrderVariant) {
    super.onRemove(input);

    if (input.id) return; // remove ccv on backend

    const costCenters = this.getCostCenters();
    costCenters.forEach(({ variants }) => {
      const index = variants.findIndex((v) => v.variantId === input.variant.id);
      if (index >= 0) variants.splice(index, 1);
    });
  }

  onDayGoalChanged(ccIndex: number, val?: number | null) {
    if (val == undefined || val === null) return;

    const costCenter = this.getCostCenters(false)[ccIndex];

    costCenter.variants.forEach((ccv) => {
      if (this.requestTotal) {
        ccv.dosage = roundUnit(ccv.amount / val, PRECISION);
      } else {
        ccv.amount = (ccv.dosage || 0) * val;
      }
    });

    if (!this.requestTotal) {
      this.get().forEach((i, index) => this.calculateTotalAmount(i, index));
    }
  }

  calculateAmountByDosage(
    costCenterIndex: number,
    input: WorkOrderVariantFragment,
    inputIndex: number,
    val?: number | null
  ) {
    const costCenters = this.getCostCenters(false);
    const amount = roundUnit(
      (val || 0) * costCenters[costCenterIndex].dayGoal,
      PRECISION
    );

    this.form.setFields([
      {
        name: this.builder.costCenters.variantFieldName(
          costCenterIndex,
          input,
          "amount"
        ),
        value: amount,
      },
    ]);

    this.calculateTotalAmount(input, inputIndex);
    this.calculateAvgDosage(inputIndex);
  }

  private calculateAvgDosage(inputIndex: number) {
    const costCenters = this.getCostCenters();
    const input = this.get(false)[inputIndex];

    const dosages: number[] = [];
    costCenters.forEach(({ variants }) => {
      const cfv = variants.find((v) => v.variantId === input.variant.id);

      dosages.push(cfv?.dosage || 0);
    });

    this.form.setFields([
      {
        name: ["inputs", inputIndex, "avgDosage"],
        value: roundUnit(mean(dosages), PRECISION),
      },
    ]);
  }

  calculateTotalAmount(input: WorkOrderVariantFragment, inputIndex: number) {
    const costCenters = this.getCostCenters();

    const total = sumBy(
      costCenters,
      (cc) => this.getVariant(cc, input)?.amount || 0
    );

    this.form.setFields([
      {
        name: ["inputs", inputIndex, "totalAmount"],
        value: total,
      },
    ]);
  }

  private calculateAmountByTotal(
    input: WorkOrderVariantFragment,
    inputIndex: number,
    val?: number | null
  ) {
    const costCenters = this.getCostCenters();

    if (val == undefined || val === null || costCenters.length === 0) return;

    const distribution = distributeProportionally(
      val,
      costCenters.map((cc) =>
        this.builder.allowDosage && cc.dayGoal ? cc.dayGoal : 1
      ),
      PRECISION
    );

    costCenters.forEach((cc, ccIndex) => {
      const ccvIndex = cc.variants.findIndex(
        (ccv) => ccv.variantId === input.variant.id
      );

      this.form.setFields([
        {
          name: ["costCenters", ccIndex, "variants", ccvIndex, "amount"],
          value: distribution[ccIndex],
        },
        {
          name: ["costCenters", ccIndex, "variants", ccvIndex, "dosage"],
          value:
            cc.dayGoal &&
            roundUnit(distribution[ccIndex] / cc.dayGoal, PRECISION),
        },
      ]);
    });

    this.calculateAvgDosage(inputIndex);
  }

  calculateByAvgDosage(
    input: WorkOrderVariantFragment,
    index: number,
    val?: number | null
  ) {
    const costCenters = this.getCostCenters(false);

    if (val == undefined || val === null || costCenters.length === 0) return;

    costCenters.forEach((cc, ccIndex) => {
      if (cc._destroy) return;

      const ccvIndex = cc.variants.findIndex(
        (ccv) => ccv.variantId === input.variant.id
      );

      this.form.setFields([
        {
          name: ["costCenters", ccIndex, "variants", ccvIndex, "dosage"],
          value: roundUnit(val, PRECISION),
        },
      ]);

      this.calculateAmountByDosage(ccIndex, input, index, val);
    });
  }

  recalculateByProgress(input: WorkOrderVariantFragment, updateState = false) {
    if (this.builder.inventoryRequestDisabled) {
      input.returnedAmount = 0;
    }

    const consumed = roundUnit(
      input.totalAmount - (input.returnedAmount || 0),
      PRECISION
    );
    const costCenters = this.getCostCenters();

    const distribution = distributeProportionally(
      consumed,
      costCenters.map((cc) =>
        this.builder.allowProgress ? cc.totalProgress : 1
      ),
      PRECISION
    );

    costCenters.forEach((cc, index) => {
      const ccvIndex = cc.variants.findIndex(
        (ccv) => ccv.variantId === input.variant.id
      );
      if (ccvIndex >= 0) {
        const ccv = cc.variants[ccvIndex];
        ccv.consumedAmount = distribution[index] || 0;
        // if ccv is new, it has 0 amount
        if (ccv.amount == 0) ccv.amount = ccv.consumedAmount || 0;

        if (updateState) {
          this.form.setFields([
            {
              name: [
                "costCenters",
                index,
                "variants",
                ccvIndex,
                "consumedAmount",
              ],
              value: distribution[index],
            },
          ]);
        }
      }
    });
  }

  recalculateAmounts(
    inputIndex: number,
    byProgress: boolean,
    costCenterIndex?: number
  ) {
    const input = this.get(false)[inputIndex];

    if (byProgress) {
      return this.recalculateByProgress(input, true);
    }

    if (costCenterIndex == undefined) return;

    const costCenters = this.getCostCenters();

    const precision = 10000;
    const totalConsumed = roundUnit(
      sumBy(
        costCenters,
        (cc) => (this.getVariant(cc, input)?.consumedAmount || 0) * precision
      ),
      PRECISION
    );

    if (this.builder.inventoryRequestDisabled || !input.id) {
      this.form.setFields([
        {
          name: ["inputs", inputIndex, "totalAmount"],
          value: roundUnit(totalConsumed / precision, PRECISION),
        },
      ]);
    } else {
      const totalAmount = roundUnit(input.totalAmount * precision, PRECISION);
      if (totalConsumed <= totalAmount) {
        this.form.setFields([
          {
            name: ["inputs", inputIndex, "returnedAmount"],
            value: roundUnit(
              (input.totalAmount * precision - totalConsumed) / precision,
              PRECISION
            ),
          },
        ]);
      }
    }
  }

  updateInput(inputIndex: number, updateStatus = true) {
    const input = this.get(false)[inputIndex];
    const costCenters = this.getCostCenters();

    const distribution = distributeProportionally(
      input.totalAmount,
      costCenters.map((cf) => cf.totalProgress || cf.dayGoal),
      PRECISION
    );

    costCenters.forEach((cc, ccIndex) => {
      const ccvIndex = cc.variants.findIndex(
        (ccv) => ccv.variantId === input.variant.id
      );

      this.form.setFields([
        {
          name: ["costCenters", ccIndex, "variants", ccvIndex, "amount"],
          value: distribution[ccIndex],
        },
        {
          name: [
            "costCenters",
            ccIndex,
            "variants",
            ccvIndex,
            "consumedAmount",
          ],
          value: distribution[ccIndex],
        },
      ]);
    });

    if (updateStatus) {
      this.form.setFields([
        {
          name: ["inputs", inputIndex, "status"],
          value: InventoryStatus.Requesting,
        },
      ]);
    }
  }

  recalculateAll() {
    const inputs = this.get();

    inputs.forEach((i) => {
      this.calculateAmountByTotal(i, i.totalAmount);
    });
  }

  private getVariant(
    costCenter: WorkOrderCostCenterFragment,
    variant: WorkOrderVariantFragment
  ) {
    return costCenter.variants.find(
      (ccv) => ccv.variantId === variant.variant.id
    );
  }
}

import { Col, Descriptions, Row, Typography } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { formatTime, formatYesNo, shouldUpdate } from "../../../lib/formats";
import { ActivityDetailsFragment } from "../../../lib/graphql";
import { Form, RadioYesNo, TimePicker } from "../../form";

export function ActivityWorkOrderSettings({
  readonly,
  activity,
}: {
  readonly?: boolean;
  activity: ActivityDetailsFragment;
}) {
  const intl = useIntl();

  return (
    <>
      <Typography.Text type="secondary">
        <FormattedMessage
          id="activities.workOrderSettingsHint"
          defaultMessage="Activity settings applied to work order flow."
        />
      </Typography.Text>

      {readonly ? (
        <Descriptions
          layout="vertical"
          column={1}
          className="details-descriptions"
        >
          <Descriptions.Item
            label={<FormattedMessage id="activities.trackCycles" />}
          >
            {formatYesNo(activity.trackCycles)}
          </Descriptions.Item>
          <Descriptions.Item
            label={<FormattedMessage id="activities.trackTime" />}
          >
            {formatYesNo(activity.trackTime)}
          </Descriptions.Item>
          {activity.startTime && (
            <Descriptions.Item
              label={<FormattedMessage id="workOrders.startTime" />}
            >
              {formatTime(activity.startTime)}
            </Descriptions.Item>
          )}
          <Descriptions.Item
            label={<FormattedMessage id="activities.trackTools" />}
          >
            {formatYesNo(activity.trackTools)}
          </Descriptions.Item>
        </Descriptions>
      ) : (
        <Row style={{ marginTop: "20px" }}>
          <Col span={12}>
            <Form.Item
              name="trackCycles"
              label={
                <FormattedMessage
                  id="activities.trackCycles"
                  defaultMessage="Track Activity Cycles?"
                />
              }
              extra={
                <FormattedMessage
                  id="activities.trackCyclesHelp"
                  defaultMessage="trackCyclesHelp"
                />
              }
            >
              <RadioYesNo />
            </Form.Item>

            <Form.Item
              name="trackTime"
              label={
                <FormattedMessage
                  id="activities.trackTime"
                  defaultMessage="Track Time?"
                />
              }
              extra={
                <FormattedMessage
                  id="activities.trackTimeHelp"
                  defaultMessage="trackTimeHelp"
                />
              }
            >
              <RadioYesNo />
            </Form.Item>

            <Form.Item noStyle shouldUpdate={shouldUpdate("trackTime")}>
              {({ getFieldValue }) => {
                if (!getFieldValue("trackTime")) return;

                return (
                  <Form.Item
                    name="startTime"
                    label={<FormattedMessage id="workOrders.startTime" />}
                  >
                    <TimePicker
                      placeholder={intl.formatMessage({
                        id: "activities.startTime.default",
                        defaultMessage: "Default Time",
                      })}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>

            <Form.Item
              name="trackTools"
              label={
                <FormattedMessage
                  id="activities.trackTools"
                  defaultMessage="Track Tools & Equipment?"
                />
              }
              extra={
                <FormattedMessage
                  id="activities.trackToolsHelp"
                  defaultMessage="trackToolsHelp"
                />
              }
            >
              <RadioYesNo />
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );
}

import { QueryHookOptions, useLazyQuery } from "@apollo/client";
import {
  VariantsQuery,
  VariantsQueryVariables,
  VariantsDocument,
  StockVariantsQuery,
  StockVariantsQueryVariables,
  StockVariantsDocument,
  SortDirection,
  VariantShortFragment,
} from "../../graphql";
import { formatStockVariantOption } from "../../formats";
import { IntlShape, useIntl } from "react-intl";
import { merge } from "lodash";
import { usePromiseQuery } from "..";

const variantMapper = (intl: IntlShape) => (v: VariantShortFragment) => ({
  key: v.id,
  label: `${v.name} - ${intl.formatNumber(v.variationValue)} ${
    v.variationUnit.abbr
  }`,
  children: formatStockVariantOption(v),
  variant: v,
});

export function useVariantOptions(
  queryOptions?: QueryHookOptions<VariantsQuery, VariantsQueryVariables>
) {
  const [load, { loading, data }] = useLazyQuery(
    VariantsDocument,
    merge({ variables: { sort: { name: SortDirection.Asc } } }, queryOptions)
  );

  const search = (val?: string) => {
    load(
      merge({ variables: { sort: undefined } }, queryOptions, {
        variables: {
          filter: {
            fullName: { match: val },
          },
        },
      })
    );
  };

  const intl = useIntl();
  const optionsFormatter = variantMapper(intl);
  const options = (data?.variants?.items || []).map(optionsFormatter);

  return { load, search, loading, options };
}

export function useStockVariantOptions(
  queryOptions?: QueryHookOptions<
    StockVariantsQuery,
    StockVariantsQueryVariables
  >
) {
  const [load, { loading, data }] = useLazyQuery<
    StockVariantsQuery,
    StockVariantsQueryVariables
  >(
    StockVariantsDocument,
    merge(
      {
        variables: { sort: { name: SortDirection.Asc } },
      },
      queryOptions
    )
  );

  const search = (value?: string) => {
    load(
      merge({ variables: { sort: undefined } }, queryOptions, {
        variables: {
          filter: {
            fullName: { match: value },
          },
        },
      })
    );
  };

  const intl = useIntl();

  const optionsFormatter = variantMapper(intl);
  const options = data?.variants?.items.map(optionsFormatter) || [];

  return { load, search, loading, options };
}

export function useStockVariants() {
  return usePromiseQuery<StockVariantsQuery, StockVariantsQueryVariables>(
    StockVariantsDocument
  );
}

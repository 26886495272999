import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import {
  formatInventoryStatus,
  formatUnitValue,
} from "../../../../lib/formats";
import { InventoryStatus, UnitType } from "../../../../lib/graphql";
import { useCurrentUser, useWorkOrderUpdate } from "../../../../lib/hooks";
import { InputNumber, Rules } from "../../../form";
import { ItemSidebar, SidebarHeader } from "../../../shared";
import { ItemForm } from "../../../shared/ListView/ItemForm";
import { UnitSelect } from "../../../units";
import { WorkOrderVariant } from "../builder/tools";
import { WorkOrderContext } from "../WorkOrderContext";
import { CurrentStockContent } from "./CurrentStockTooltip";

export function EditInventoryRequestSidebar({
  actionName,
  variant,
  onChanged,
}: {
  actionName: string;
  variant?: WorkOrderVariant;
  onChanged?: (value: WorkOrderVariant) => void;
}) {
  const { workOrder, builder } = useContext(WorkOrderContext);
  const [update] = useWorkOrderUpdate();
  const { currentTenant } = useCurrentUser();

  if (builder.isReadonly) return null;

  return (
    <ItemSidebar
      item={variant}
      sidebarActions={{
        [actionName]: ({ item, closeSidebar }) => {
          if (!item) return;

          const isUnitary = item.unit.unitType == UnitType.Unitary;
          const rules = [];
          if (isUnitary) {
            rules.push(Rules.onlyIntegers);
          }

          return (
            <>
              <SidebarHeader
                back
                title={<FormattedMessage id="inventoryRequests.details" />}
                onClose={closeSidebar}
              />
              <ItemForm
                initialValues={item}
                fields={[
                  {
                    label: (
                      <FormattedMessage id="inventoryRequests.currentStock" />
                    ),
                    name: "id",
                    type: "custom",
                    render: () => (
                      <CurrentStockContent
                        variant={item}
                        date={workOrder.documentDate}
                        localityId={workOrder.locality.id}
                      />
                    ),
                  },
                  {
                    label: <FormattedMessage id="workOrders.inventoryStatus" />,
                    name: "status",
                    type: "custom",
                    render: () => formatInventoryStatus(item.status),
                  },
                  {
                    label:
                      item.status == InventoryStatus.Assigned ? (
                        <FormattedMessage id="variants.qtyAssigned" />
                      ) : (
                        <FormattedMessage id="variants.qtyRequested" />
                      ),
                    name: "assignedAmount",
                    type: "custom",
                    render: () =>
                      formatUnitValue(item.totalAmount, item.unit, null, {
                        maximumFractionDigits: 4,
                      }),
                  },
                  {
                    label: (
                      <FormattedMessage
                        id="workOrders.newAmountRequested"
                        defaultMessage="New Amount Requested"
                      />
                    ),
                    name: "totalAmount",
                    type: "custom",
                    rules: rules,
                    render: () => (
                      <InputNumber
                        step={isUnitary ? 1 : 0.1}
                        precision={isUnitary ? 0 : 4}
                        min={0}
                        addonAfter={
                          isUnitary ? (
                            item.unit.abbr
                          ) : (
                            <UnitSelect.FormItem
                              name={["unit"]}
                              unitTypes={[item.variant.variationUnit.unitType]}
                              style={{ minWidth: "100px" }}
                            />
                          )
                        }
                      />
                    ),
                  },
                ]}
                onCancel={closeSidebar}
                saveText={<FormattedMessage id="workOrders.submitRequest" />}
                onSave={(value) => {
                  return update({
                    variables: {
                      id: workOrder.id,
                      changes: {
                        variantsAttributes: [
                          {
                            id: value.id,
                            totalAmount: value.totalAmount,
                            unitId: value.unit.id,
                            variantId: value.variant.id,
                          },
                        ],
                      },
                    },
                  }).then((data) => {
                    const result = data.data?.workOrderUpdate;
                    if (!result?.errors.length) {
                      closeSidebar();

                      if (onChanged)
                        onChanged({
                          ...value,
                          status: currentTenant.inventoryRequestDisabled
                            ? value.status
                            : InventoryStatus.Requesting,
                        });
                    }
                    return result;
                  });
                }}
              />
            </>
          );
        },
      }}
    />
  );
}

import { FormattedMessage } from "react-intl";
import { TableInput } from "../../shared";
import {
  WorkOrderCostCenterFragment,
  ItemKind,
  InventoryStatus,
  WorkOrderVariantFragment,
} from "../../../lib/graphql";
import {
  formatUnitValue,
  formatProfitableName,
  formatCostCenterName,
  variantSelectDropdown,
  formatVariantLink,
  shouldUpdate as shouldUpdateWith,
  formatInventoryRequestLink,
  formatUnitValueConversion,
} from "../../../lib/formats";

import { useContext } from "react";
import { useStockVariantOptions } from "../../../lib/hooks/inventory/variants";
import { Form, Rules, InputNumber } from "../../form";
import { Typography } from "antd";
import { WorkOrderContext } from "./WorkOrderContext";
import { ColumnsType } from "antd/lib/table";
import { DosageCalculator, UnitSelect } from "../../units";
import { useCurrentUser } from "../../../lib/hooks";
import { WorkOrderVariant } from "./builder/tools";
import { CurrentStockTooltip } from "./inventory";

function shouldUpdate(prev: any, next: any) {
  return (
    prev.costCenters.filter(Form.undestroyed).length !==
      next.costCenters.filter(Form.undestroyed).length ||
    prev.inputs.length !== next.inputs.length ||
    prev.costCenters.findIndex(
      (c: WorkOrderCostCenterFragment, index: number) => {
        const cc = next.costCenters[index];
        return c.dayGoal !== cc?.dayGoal;
      }
    ) >= 0
  );
}

function shouldUpdateOnAmountChange(index: number) {
  return (prev: any, next: any) =>
    prev.inputs[index] != next.inputs[index] ||
    prev.costCenters.findIndex(
      (c: WorkOrderCostCenterFragment, index: number) => {
        const cc = next.costCenters[index];
        return c.variants !== cc?.variants;
      }
    ) >= 0;
}

export function isInputReadonly(input: WorkOrderVariantFragment) {
  return (
    input.status !== InventoryStatus.NotRequested &&
    input.status !== InventoryStatus.Requesting
  );
}

export function WorkOrderInputs({
  readonly,
  mode,
  noDosages = false,
}: {
  readonly: boolean;
  noDosages?: boolean;
  mode: "requestTotal" | "requestByDosage" | "requestBySubtotal";
}) {
  const { workOrder, builder } = useContext(WorkOrderContext);
  const { currentTenant } = useCurrentUser();
  const progressUnit = workOrder.activity.progressUnit;
  const requestTotal = mode === "requestTotal";

  return (
    <Form.Item noStyle shouldUpdate={shouldUpdate}>
      {({ getFieldValue, setFieldValue }) => {
        if (!readonly) {
          builder.inputs.initInputs(requestTotal);
        }

        const costCenterColumns = (
          variant: WorkOrderVariantFragment,
          variantIndex: number
        ) => {
          const columns: ColumnsType<WorkOrderCostCenterFragment> = [
            {
              dataIndex: "name",
              render: (_, cc) => (
                <>
                  {formatProfitableName(cc)}
                  <br />
                  <Typography.Text
                    type="secondary"
                    style={{ fontSize: "12px" }}
                  >
                    {noDosages ? (
                      formatCostCenterName(cc.costCenter.parentCostCenter)
                    ) : (
                      <span>
                        <FormattedMessage id="workOrders.dayGoal" />:{" "}
                        {formatUnitValue(cc.dayGoal, progressUnit)}
                      </span>
                    )}
                  </Typography.Text>
                </>
              ),
            },
            {
              title: (
                <FormattedMessage
                  id="dosage.perCropField"
                  defaultMessage="Dosage per Crop Field"
                />
              ),
              align: "center",
              width: "15rem",
              render: (_, cc, index) =>
                readonly || requestTotal || isInputReadonly(variant) ? (
                  <Form.Item
                    noStyle
                    shouldUpdate={shouldUpdateOnAmountChange(variantIndex)}
                  >
                    {() =>
                      formatUnitValue(
                        getFieldValue(
                          builder.costCenters.variantFieldName(
                            index,
                            variant,
                            "amount"
                          )
                        ) / cc.dayGoal,
                        getFieldValue(["inputs", variantIndex, "unit"]),
                        progressUnit.abbr,
                        { maximumFractionDigits: 4 }
                      )
                    }
                  </Form.Item>
                ) : (
                  <Form.Item
                    compact
                    name={builder.costCenters.variantFieldName(
                      index,
                      variant,
                      "dosage"
                    )}
                    rules={[Rules.gtEqZero]}
                  >
                    <InputNumber
                      min={0}
                      step={0.1}
                      precision={4}
                      addonBefore={
                        cc.cropField ? (
                          <Form.Item
                            noStyle
                            shouldUpdate={shouldUpdateWith(
                              ["inputs", variantIndex, "unit"],
                              ["inputs", variantIndex, "calculatorUnit"]
                            )}
                          >
                            {() => (
                              <DosageCalculator
                                variantUnit={variant.unit}
                                defaultUnit={getFieldValue([
                                  "inputs",
                                  variantIndex,
                                  "calculatorUnit",
                                ])}
                                progressUnit={progressUnit}
                                cropFields={[
                                  {
                                    goal: cc.dayGoal,
                                    plantDensity:
                                      cc.cropField?.plantDensity || 0,
                                  },
                                ]}
                                onOk={(value) => {
                                  setFieldValue(
                                    builder.costCenters.variantFieldName(
                                      index,
                                      variant,
                                      "dosage"
                                    ),
                                    value
                                  );

                                  builder.inputs.calculateAmountByDosage(
                                    index,
                                    variant,
                                    variantIndex,
                                    value
                                  );
                                }}
                              />
                            )}
                          </Form.Item>
                        ) : undefined
                      }
                      addonAfter={
                        <UnitSelect.FormItem
                          name={["inputs", variantIndex, "unit"]}
                          unitTypes={[variant.variant.variationUnit.unitType]}
                          labelRenderer={(label) =>
                            `${label} / ${progressUnit.abbr}`
                          }
                        />
                      }
                      onChange={(val) =>
                        builder.inputs.calculateAmountByDosage(
                          index,
                          variant,
                          variantIndex,
                          val
                        )
                      }
                    />
                  </Form.Item>
                ),
            },
            {
              title: (
                <FormattedMessage id="subtotal" defaultMessage="subtotal" />
              ),
              align: "center",
              width: 230,
              render: (_, _cc, index) => {
                const name = builder.costCenters.variantFieldName(
                  index,
                  variant,
                  "amount"
                );
                return mode === "requestBySubtotal" && !readonly ? (
                  <Form.Item name={name} compact>
                    <InputNumber
                      step={0.1}
                      min={0}
                      precision={4}
                      addonAfter={
                        <UnitSelect.FormItem
                          name={["inputs", variantIndex, "unit"]}
                          unitTypes={[variant.variant.variationUnit.unitType]}
                        />
                      }
                      onChange={() =>
                        builder.inputs.calculateTotalAmount(
                          variant,
                          variantIndex
                        )
                      }
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    noStyle
                    shouldUpdate={shouldUpdateOnAmountChange(variantIndex)}
                  >
                    {() => {
                      return formatUnitValue(
                        getFieldValue(name),
                        getFieldValue(["inputs", variantIndex, "unit"]),
                        null,
                        { maximumFractionDigits: 4 }
                      );
                    }}
                  </Form.Item>
                );
              },
            },
          ];
          if (noDosages) columns.splice(1, 1);
          return columns;
        };

        const columns: ColumnsType<WorkOrderVariant> = [
          {
            title: <FormattedMessage id="variants.entityName" />,
            dataIndex: "variant",
            render: (_, v) => formatVariantLink(v.variant),
          },
          {
            width: "1rem",
            render: (_, v) => (
              <CurrentStockTooltip
                variant={v}
                date={workOrder.documentDate}
                localityId={workOrder.locality.id}
              />
            ),
          },
          {
            title: <FormattedMessage id="dosage" />,
            dataIndex: "avgDosage",
            width: "16rem",
            align: "center",
            render: (_, v, index) =>
              requestTotal || readonly || isInputReadonly(v) ? (
                <Form.Item
                  noStyle
                  shouldUpdate={shouldUpdateOnAmountChange(index)}
                >
                  {() =>
                    formatUnitValue(
                      getFieldValue(["inputs", index, "avgDosage"]),
                      getFieldValue(["inputs", index, "unit"]),
                      progressUnit.abbr,
                      { maximumFractionDigits: 4 }
                    )
                  }
                </Form.Item>
              ) : (
                <Form.Item compact name={["inputs", index, "avgDosage"]}>
                  <InputNumber
                    min={0}
                    step={0.1}
                    precision={4}
                    addonAfter={
                      <UnitSelect.FormItem
                        name={["inputs", index, "unit"]}
                        unitTypes={[v.variant.variationUnit.unitType]}
                        labelRenderer={(label) =>
                          `${label} / ${progressUnit.abbr}`
                        }
                      />
                    }
                    addonBefore={
                      <Form.Item
                        noStyle
                        shouldUpdate={shouldUpdateWith([
                          "inputs",
                          index,
                          "unit",
                        ])}
                      >
                        {() => (
                          <DosageCalculator
                            variantUnit={getFieldValue([
                              "inputs",
                              index,
                              "unit",
                            ])}
                            progressUnit={progressUnit}
                            cropFields={builder.costCenters
                              .get()
                              .filter((cc) => cc.cropField)
                              .map((cc) => ({
                                goal: cc.dayGoal,
                                plantDensity: cc.cropField?.plantDensity || 0,
                              }))}
                            onVariantUnitChanged={(unit) =>
                              setFieldValue(
                                ["inputs", index, "calculatorUnit"],
                                unit
                              )
                            }
                            onOk={(value) => {
                              setFieldValue(
                                ["inputs", index, "avgDosage"],
                                value
                              );
                              builder.inputs.calculateByAvgDosage(
                                v,
                                index,
                                value
                              );
                            }}
                          />
                        )}
                      </Form.Item>
                    }
                    onChange={(val) =>
                      builder.inputs.calculateByAvgDosage(v, index, val)
                    }
                  />
                </Form.Item>
              ),
          },
          {
            title: (
              <FormattedMessage
                id="workOrders.totalRequested"
                defaultMessage="totalRequested"
              />
            ),
            dataIndex: "totalAmount",
            width: 185,
            align: "center",
            render: (_, v, index) => (
              <Form.Item
                noStyle
                shouldUpdate={shouldUpdateOnAmountChange(index)}
              >
                {() => {
                  if (requestTotal && !readonly && !isInputReadonly(v)) {
                    return (
                      <Form.Item
                        compact
                        name={["inputs", index, "totalAmount"]}
                        rules={[Rules.gtEqZero]}
                      >
                        <InputNumber
                          step={0.1}
                          min={0}
                          precision={4}
                          addonAfter={
                            <UnitSelect.FormItem
                              name={["inputs", index, "unit"]}
                              unitTypes={[v.variant.variationUnit.unitType]}
                            />
                          }
                          onChange={(val) =>
                            builder.inputs.debouncedCalculateAmountByTotal(
                              v,
                              index,
                              val
                            )
                          }
                        />
                      </Form.Item>
                    );
                  }

                  const totalAmount = getFieldValue([
                    "inputs",
                    index,
                    "totalAmount",
                  ]);
                  const unit = getFieldValue(["inputs", index, "unit"]);

                  return formatUnitValueConversion(totalAmount, {
                    unit,
                    conversionUnit: v.variant.variationUnit,
                    inverse: true,
                  });
                }}
              </Form.Item>
            ),
          },
        ];

        if (!currentTenant.inventoryRequestDisabled) {
          columns.push({
            title: <FormattedMessage id="workOrders.inventoryStatus" />,
            width: 185,
            align: "center",
            dataIndex: "status",
            render: (_, v) =>
              formatInventoryRequestLink(workOrder.id, v.status),
          });
        }

        if (noDosages) columns.splice(2, 1);

        return (
          <TableInput
            name="inputs"
            rules={[Rules.required]}
            dataSource={workOrder.inputs}
            tableProps={{
              bordered: true,
              expandable: {
                rowExpandable: (variant) =>
                  builder.costCenters.hasItems && Form.undestroyed(variant),

                expandedRowRender: (variant, variantIndex) => {
                  return (
                    <TableInput
                      name="costCenters"
                      dataSource={workOrder.costCenters}
                      hideMenu
                      tableProps={{
                        size: "small",
                      }}
                      rowKey={(c) => c.costCenter.id + c.profitableId}
                      columns={costCenterColumns(variant, variantIndex)}
                    />
                  );
                },
              },
            }}
            rowKey={(f) => f.variant.id}
            disabled={readonly}
            onRemove={(v) => builder.inputs.onRemove(v)}
            allowBulkRemove
            tableSelectProps={{
              mode: "multiple",
              optionsHook: useStockVariantOptions,
              optionsHookParams: {
                variables: {
                  localityId: workOrder.locality.id,
                  date: workOrder.documentDate,
                  filter: { itemKind: [ItemKind.Consumable] },
                },
              },
              placeholder: (
                <FormattedMessage
                  id="select.variants"
                  defaultMessage="variants"
                />
              ),
              dropdownRender: variantSelectDropdown(),
              entityById: (_, { variant }) => {
                if (variant) {
                  return {
                    id: "",
                    variant,
                    totalAmount: 0,
                    status: InventoryStatus.NotRequested,
                    unit: variant.variationUnit,
                  };
                }
              },
            }}
            addSorter={(a, b) => builder.inputs.sorter(a, b)}
            columns={columns}
          />
        );
      }}
    </Form.Item>
  );
}

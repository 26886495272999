import { Descriptions, Tag } from "antd";
import { ActivityDetailsFragment, UnitType } from "../../../lib/graphql";
import { FormattedMessage } from "react-intl";
import {
  useActivityLaborForceTypesFormat,
  useActivityProgressMeasureTypesFormat,
  useWorkOrderTypeFormat,
} from "../../../lib/hooks";
import { BLANK_SYMBOL } from "../../../lib/formats";

export function ActivityBasicInfo({
  activity,
}: {
  activity: ActivityDetailsFragment;
}) {
  const formatActivityProgressMeasureType =
    useActivityProgressMeasureTypesFormat();
  const formatActivityLaborForceType = useActivityLaborForceTypesFormat();
  const { formatWorkOrderType } = useWorkOrderTypeFormat();

  return (
    <Descriptions layout="vertical" column={1} className="details-descriptions">
      <Descriptions.Item
        label={
          <FormattedMessage
            id="activities.entityName"
            defaultMessage="activity"
          />
        }
      >
        {activity.name}
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id="activities.category" />}>
        {activity.category.name}
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id="internalId" />}>
        {activity.internalId || BLANK_SYMBOL}
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id="description" />}>
        {activity.description || BLANK_SYMBOL}
      </Descriptions.Item>
      <Descriptions.Item
        label={<FormattedMessage id="activities.workOrderType" />}
      >
        {formatWorkOrderType(activity.workOrderType)}
      </Descriptions.Item>
      <Descriptions.Item
        label={<FormattedMessage id="activities.laborForceType" />}
      >
        {formatActivityLaborForceType(activity.laborForceType)}
      </Descriptions.Item>
      <Descriptions.Item
        label={<FormattedMessage id="activities.workdayHours" />}
      >
        {activity.workdayHours} <FormattedMessage id="hoursPerDay" />
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id="localities" />}>
        {activity.localities.length > 0 ? (
          activity.localities.map((l) => l.name).join(", ")
        ) : (
          <FormattedMessage id="localities.all" />
        )}
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id="crops" />}>
        {activity.crops.length > 0 ? (
          activity.crops.map((c) => c.name).join(", ")
        ) : (
          <FormattedMessage id="crops.all" />
        )}
      </Descriptions.Item>
      {activity.progressUnit.unitType !== UnitType.Time && (
        <>
          <Descriptions.Item
            label={<FormattedMessage id="activities.progressUnit" />}
          >
            {activity.progressUnit.name}
          </Descriptions.Item>
          <Descriptions.Item
            label={<FormattedMessage id="activities.progressMeasureType" />}
          >
            {formatActivityProgressMeasureType(activity.progressMeasureType)}
          </Descriptions.Item>
        </>
      )}

      <Descriptions.Item
        label={
          <FormattedMessage
            id="activities.doneOver"
            defaultMessage="Done over"
          />
        }
      >
        <FormattedMessage
          id={
            activity.useTotalArea
              ? "cropFields.totalArea"
              : "cropFields.effectiveArea"
          }
        />
      </Descriptions.Item>

      {activity.activityMetrics.length && (
        <Descriptions.Item label={<FormattedMessage id="metrics" />}>
          {activity.activityMetrics.map((am) => (
            <Tag key={am.id}>
              {am.metric.name} ({am.metric.unit.abbr})
            </Tag>
          ))}
        </Descriptions.Item>
      )}
    </Descriptions>
  );
}

import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { Space, Tag } from "antd";
import { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  colors,
  formatInventoryRequestLink,
  formatInventoryStatus,
  formatStock,
} from "../../../../lib/formats";
import {
  InventoryStatus,
  StockFragment,
  WarehouseFragment,
} from "../../../../lib/graphql";
import { useCurrentUser } from "../../../../lib/hooks";
import { DeepNamePath, Form } from "../../../form";
import { StockWarehouseSelect } from "../../../warehouses";
import { WorkOrderVariant } from "../builder/tools";
import { WorkOrderContext } from "../WorkOrderContext";
import { useUpdateEffect } from "react-use";

export function InventoryRequestWarehouseCell({
  name,
}: {
  name: DeepNamePath;
}) {
  const { currentTenant } = useCurrentUser();
  const { workOrder, builder } = useContext(WorkOrderContext);
  const [selectedWarehouse, setSelectedWarehouse] = useState<
    WarehouseFragment & {
      stock: Pick<StockFragment, "onHand">;
    }
  >();

  const variant = builder.form.getFieldValue(name) as WorkOrderVariant;

  useUpdateEffect(() => {
    builder.form.validateFields([name.concat("sourceWarehouseId")]);
  }, [selectedWarehouse]);

  if (!currentTenant.inventoryRequestDisabled) {
    return formatInventoryRequestLink(workOrder.id, variant.status);
  }

  if (
    builder.isReadonly ||
    (variant.status !== InventoryStatus.Requesting &&
      variant.status !== InventoryStatus.NotRequested)
  ) {
    return (
      <Space direction="vertical">
        <Tag color="success">{variant.sourceWarehouse?.name}</Tag>
        {formatInventoryStatus(variant.status)}
      </Space>
    );
  }

  return (
    <Form.Item
      name={name.concat("sourceWarehouseId")}
      compact
      rules={[
        () => ({
          validator(_rule, value) {
            if (variant._destroy || !builder.isCompleting)
              return Promise.resolve();

            if (
              value &&
              selectedWarehouse &&
              selectedWarehouse.stock &&
              selectedWarehouse.stock.onHand >= variant.totalAmount
            ) {
              return Promise.resolve();
            }
            return Promise.reject(
              <FormattedMessage
                id="stock.unsufficient"
                defaultMessage="unsufficient"
              />
            );
          },
        }),
      ]}
    >
      <StockWarehouseSelect
        variant={variant.variant}
        unit={variant.unit}
        autoSelect={!variant.sourceWarehouse}
        defaultWarehouseId={variant.sourceWarehouseId}
        localityId={workOrder.locality.id}
        date={workOrder.documentDate}
        style={{ textAlign: "left" }}
        labelRenderer={(label, option) => {
          const stock = option.warehouse.stock.onHand;
          return (
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <>
                  {stock &&
                  stock >= getFieldValue(name.concat("totalAmount")) ? (
                    <CheckCircleTwoTone
                      twoToneColor={colors.successColor}
                      style={{ marginRight: "8px" }}
                    />
                  ) : (
                    <CloseCircleTwoTone
                      twoToneColor={colors.dangerColor}
                      style={{ marginRight: "8px" }}
                    />
                  )}
                  {label}
                  {formatStock(stock, variant.unit)}
                </>
              )}
            </Form.Item>
          );
        }}
        onChangeWarehouse={(warehouse) => {
          setSelectedWarehouse(warehouse);
        }}
      />
    </Form.Item>
  );
}

import { Col, Divider, FormInstance, Input, Row, Spin } from "antd";
import { sumBy } from "lodash";
import dynamic from "next/dynamic";
import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  formatEntityNameLabel,
  rtfValues,
  shouldUpdate,
} from "../../../lib/formats";
import {
  CropFieldFragment,
  CropFieldVarietyFragment,
  CropStageFragment,
} from "../../../lib/graphql";
import {
  Destroy,
  useCropFieldGroupOptions,
  useCropStageOptions,
  useCurrentUser,
  useItemSidebarContext,
  useLocalityOptions,
} from "../../../lib/hooks";
import { CostCenterSelect } from "../../finance";
import { Form, InputNumber, Rules, SelectField } from "../../form";
import { HelpTooltip } from "../../shared";
import { CropSelect } from "../CropSelect";
import { CropFieldStats } from "./CropFieldStats";
import { CropFieldVarieties } from "./CropFieldVarieties";
import { NewCropStageSidebar } from "../cropStages/NewCropStageSidebar";

const MapInput = dynamic(() => import("../../shared/MapInput/index"), {
  ssr: false,
  loading: () => <Spin />,
});

const LocalityMap = dynamic(() => import("../localities/LocalityMap"), {
  ssr: false,
  loading: () => <Spin />,
});

export function CropFieldBasicForm({
  cropField,
  onboarding,
  form,
}: {
  cropField?: CropFieldFragment;
  onboarding?: boolean;
  form: FormInstance;
}) {
  const { setCurrentAction } = useItemSidebarContext();
  const { user, currentLocality } = useCurrentUser();
  const [selectedLocality, setCurrentLocality] = useState(
    cropField?.locality || (onboarding ? undefined : currentLocality)
  );
  const areaAbbr = selectedLocality?.areaUnit.abbr || "m";

  const cropFieldGroup = form.getFieldValue("group");

  const onAreaChange = useCallback(
    (area: number) => {
      const factor = selectedLocality?.areaUnit.conversionFactor || 1;

      form.setFields([
        {
          name: "totalArea",
          value: Math.round((area * 100) / factor) / 100,
        },
      ]);
    },
    [form, selectedLocality?.areaUnit.conversionFactor]
  );

  return (
    <>
      <Row gutter={32}>
        <Col xs={24} md={11}>
          <HelpTooltip
            title={
              <FormattedMessage
                id="cropFields.nameHelp"
                defaultMessage="nameHelp"
              />
            }
          >
            <Form.Item
              name="name"
              label={formatEntityNameLabel(
                <FormattedMessage
                  id="cropFields.entityName"
                  defaultMessage="cropField"
                />
              )}
              rules={[Rules.required]}
              required
            >
              <Input />
            </Form.Item>
          </HelpTooltip>

          <HelpTooltip
            title={
              <FormattedMessage
                id="cropFields.internalIdHelp"
                defaultMessage="internalIdHelp"
              />
            }
          >
            <Form.Item
              name="internalId"
              label={
                <FormattedMessage id="internalId" defaultMessage="internalId" />
              }
            >
              <Input />
            </Form.Item>
          </HelpTooltip>

          <HelpTooltip
            title={
              <FormattedMessage
                id="cropFields.cropHelp"
                defaultMessage="cropHelp"
              />
            }
          >
            <Form.Item
              name="cropId"
              label={<FormattedMessage id="crops.entityName" />}
              rules={[Rules.required]}
              required
            >
              <CropSelect />
            </Form.Item>
          </HelpTooltip>

          {(cropField?.id || onboarding) && (
            <HelpTooltip
              title={
                <FormattedMessage
                  id="cropFields.localityHelp"
                  defaultMessage="localityHelp"
                />
              }
            >
              <Form.Item
                name="localityId"
                label={<FormattedMessage id="localities.entityName" />}
                rules={[Rules.required]}
              >
                <SelectField
                  optionsHook={useLocalityOptions}
                  onChange={(v, options) =>
                    setCurrentLocality(
                      options.find((o: any) => o.key === v)?.locality
                    )
                  }
                />
              </Form.Item>
            </HelpTooltip>
          )}

          <HelpTooltip
            title={
              <FormattedMessage
                id="cropFields.cropStageHelp"
                defaultMessage="cropStageHelp"
              />
            }
          >
            <Form.Item noStyle shouldUpdate={shouldUpdate("localityId")}>
              {({ getFieldValue, setFieldValue }) => {
                const localityId = getFieldValue("localityId");

                return (
                  <Form.Item
                    name="cropStageId"
                    label={<FormattedMessage id="cropStages.entityName" />}
                    rules={[Rules.required]}
                  >
                    <SelectField
                      showGroups
                      optionsHook={useCropStageOptions}
                      optionsHookParams={{
                        variables: { filter: { localityId } },
                      }}
                      newItemProps={
                        user?.permissions?.cropStage?.write
                          ? {
                              entityName: (
                                <FormattedMessage id="cropStages.entityName" />
                              ),
                              onClick: () => setCurrentAction("newCropStage"),
                            }
                          : undefined
                      }
                      onChange={(id, options) => {
                        const stage = options.find((o: any) => o.key == id)
                          ?.stage as CropStageFragment;
                        if (stage) {
                          const costCenter =
                            stage.cropStageLocalities?.find(
                              (l) => l.locality.id == localityId
                            )?.costCenter || stage.costCenter;

                          setFieldValue("costCenterId", costCenter?.id);
                        }
                      }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </HelpTooltip>

          <HelpTooltip
            title={
              <FormattedMessage
                id="cropFields.groupHelp"
                defaultMessage="groupHelp"
                values={rtfValues}
              />
            }
          >
            <Form.Item
              name="group"
              label={<FormattedMessage id="cropFields.group" />}
            >
              <SelectField
                optionsHook={useCropFieldGroupOptions}
                optionsHookParams={{
                  variables: {
                    filter: {
                      localityId: selectedLocality
                        ? [selectedLocality.id]
                        : undefined,
                    },
                  },
                }}
                // setting newly created groups as additional option
                options={
                  cropFieldGroup
                    ? [{ key: cropFieldGroup, label: cropFieldGroup }]
                    : []
                }
                newItemProps={{
                  entityName: <FormattedMessage id="cropFields.group" />,
                  onClick: () => {
                    setCurrentAction("newZone");
                  },
                }}
              />
            </Form.Item>
          </HelpTooltip>

          {!onboarding && (
            <Form.Item
              name="costCenterId"
              label={<FormattedMessage id="costCenters.entityName" />}
            >
              <CostCenterSelect useDefault />
            </Form.Item>
          )}
        </Col>

        <Col xs={24} md={{ span: 11, offset: 2 }}>
          <Form.Item
            name="geoJson"
            label={
              <FormattedMessage id="cropFields.map" defaultMessage="location" />
            }
          >
            <MapInput
              disablePoint
              defaultValue={cropField?.geoJson}
              onAreaChange={onAreaChange}
            >
              <LocalityMap hideCropFields locality={selectedLocality} />
            </MapInput>
          </Form.Item>

          <HelpTooltip
            title={
              <FormattedMessage
                id="cropFields.totalAreaHelp"
                defaultMessage="totalAreaHelp"
              />
            }
          >
            <Form.Item
              name="totalArea"
              label={<FormattedMessage id="cropFields.totalArea" />}
              rules={[Rules.gtZero]}
            >
              <InputNumber
                min={0}
                step={0.01}
                precision={2}
                max={99999999}
                addonAfter={areaAbbr}
              />
            </Form.Item>
          </HelpTooltip>

          {!onboarding && (
            <Form.Item
              noStyle
              shouldUpdate={shouldUpdate("cropFieldVarieties")}
            >
              {({ getFieldValue }) => {
                const cropFieldVarieties = getFieldValue(
                  "cropFieldVarieties"
                ) as CropFieldVarietyFragment[];

                const plantDensity =
                  sumBy(cropFieldVarieties, "plantDensity") /
                  cropFieldVarieties.length;
                const effectivePlants = sumBy(
                  cropFieldVarieties,
                  "effectivePlants"
                );
                const effectiveArea = sumBy(
                  cropFieldVarieties,
                  (v) => v.effectivePlants / v.plantDensity
                );

                return (
                  <CropFieldStats
                    stats={{
                      plantDensity,
                      effectivePlants,
                      effectiveArea,
                      cropFieldVarieties,
                    }}
                    areaAbbr={areaAbbr}
                  />
                );
              }}
            </Form.Item>
          )}
        </Col>

        <Col xs={24}>
          <Divider>
            <FormattedMessage id="cropVarieties" />
          </Divider>

          <Form.Item rules={[Rules.required]} name="cropFieldVarieties">
            <Form.Item
              noStyle
              shouldUpdate={shouldUpdate("cropFieldVarieties")}
            >
              {({ getFieldValue }) => {
                const varieties = getFieldValue(
                  "cropFieldVarieties"
                ) as Destroy<CropFieldVarietyFragment>[];
                const totalArea = getFieldValue("totalArea") as number;
                return (
                  <CropFieldVarieties
                    varieties={varieties}
                    totalArea={totalArea}
                  />
                );
              }}
            </Form.Item>
          </Form.Item>
        </Col>
      </Row>

      <NewCropStageSidebar
        onSave={(cropStage) => {
          if (cropStage) {
            const localityId = form.getFieldValue("localityId");

            const costCenter =
              cropStage.cropStageLocalities?.find(
                (l) => l.locality.id == localityId
              )?.costCenter || cropStage.costCenter;

            form.setFields([
              { name: "cropStageId", value: cropStage.id },
              {
                name: "costCenterId",
                value: costCenter?.id,
              },
            ]);
          }
        }}
      />
    </>
  );
}

import { WorkOrderBuilder } from "..";
import { WorkOrderStatus } from "../../../../../lib/graphql";
import { SectionProps } from "../../../../shared";
import { SectionBuilder } from "../../../../shared/Wizard/sectionBuilder";
import { CostCenters } from "./CostCenters";
import { CropFields } from "./CropFields";
import { Attendance, Employees } from "./Employees";
import { Infrastructures, ProcessingPlants } from "./Infrastructures";
import { ConsumedInputs, Inputs } from "./Inputs";
import { MachineMaintenance, Machines, UsedMachines } from "./Machines";
import { Outputs, ProcessOutputs } from "./Outputs";
import { Summary } from "./Summary";
import { Tokens } from "./Tokens";
import { Tools, UsedTools } from "./Tools";

export class WorkOrderSectionBuilder extends SectionBuilder {
  protected builder: WorkOrderBuilder;

  constructor(builder: WorkOrderBuilder) {
    super();

    this.builder = builder;
    this.current = builder.noActivity
      ? this.noActivitySections()
      : builder.isDataIntake
      ? this.inProgressSections()
      : this.openSections();
  }

  workOrderState() {
    return {
      dataIntake: this.builder.isDataIntake,
      readonly: this.builder.isReadonly,
      isMachinery: this.builder.isMachinery,
      isAgricultural: this.builder.isAgricultural,
      isInfrastructural: this.builder.isInfrastructural,
      isHarvest: this.builder.isHarvest,
      allowProgress: this.builder.allowProgress,
      hasEmployees:
        this.builder.workOrder.employees.length > 0 ||
        !!this.builder.workerCountMode,
      hasMachinery: this.builder.workOrder.machines.length > 0,
      hasInputs: this.builder.workOrder.inputs.length > 0,
      hasTools: this.builder.workOrder.tools.length > 0,
      hasTokens: this.builder.workOrder.tokens.length > 0,
      allowTokens: this.builder.user?.currentTenant?.allowTokens,
      progressByIndividual: this.builder.progressByIndividual,
      isMachineryMaintenance: this.builder.isMachineryMaintenance,
      isTransitAndTransport: this.builder.isTransitAndTransport,
      isProcessing: this.builder.isProcessing,
      trackTools: !!this.builder.workOrder.activity.trackTools,
      isCompleted: this.builder.workOrder.status === WorkOrderStatus.Completed,
      showEmployeeProgress: this.builder.showEmployeeProgress,
    };
  }

  onHideSection(section: SectionProps) {
    super.onHideSection(section);

    this.onSectionRemoved(section.key);
  }

  onChangeSectionView(section: SectionProps, viewIndex: number) {
    super.onChangeSectionView(section, viewIndex);

    if (section.viewProperty) {
      this.builder.form.setFields([
        {
          name: section.viewProperty,
          value: viewIndex === 1,
        },
      ]);
    }
  }

  onSectionRemoved(key: string) {
    switch (key) {
      case "inputs":
        this.builder.inputs.removeAll();
        break;
      case "tools":
        this.builder.tools.removeAll();
        break;
      case "tokens":
        this.builder.tokens.removeAll();
        break;
      case "machines":
        this.builder.machines.removeAll();
        break;
      case "attendance":
        this.builder.employees.removeAll();
        break;
    }
  }

  private noActivitySections() {
    return [
      CropFields(false, this.builder),
      Employees(false, false, false, false, false, false, false),
      Inputs(false, false, false, false, false),
    ];
  }

  private openSections() {
    const {
      isHarvest,
      isAgricultural,
      readonly,
      allowProgress,
      isInfrastructural,
      hasEmployees,
      hasTokens,
      isMachinery,
      hasMachinery,
      hasInputs,
      hasTools,
      allowTokens,
      isMachineryMaintenance,
      isTransitAndTransport,
      isProcessing,
      trackTools,
    } = this.workOrderState();

    const sections: SectionProps[] = [
      isProcessing
        ? ProcessingPlants(this.builder)
        : isAgricultural
        ? CropFields(true, this.builder)
        : isInfrastructural
        ? Infrastructures(this.builder)
        : isMachineryMaintenance || isTransitAndTransport
        ? MachineMaintenance(this.builder)
        : CostCenters(this.builder),
      Employees(
        (allowProgress && hasEmployees) || !isHarvest,
        readonly,
        isAgricultural && !isHarvest,
        isInfrastructural,
        isMachineryMaintenance,
        isTransitAndTransport,
        isProcessing
      ),
      Inputs(
        hasInputs,
        readonly,
        !!this.builder.workOrder.requestByTotal,
        allowProgress && isAgricultural && !isHarvest,
        isHarvest && !hasInputs
      ),
    ];

    sections.push(Tools(hasTools || trackTools, readonly));

    if (!isMachineryMaintenance && !isTransitAndTransport && !isProcessing) {
      sections.splice(2, 0, Machines(isMachinery || hasMachinery));
    }

    if (isProcessing) {
      sections.splice(1, 0, ProcessOutputs());
    }

    if (isHarvest && allowTokens) {
      sections.push(Tokens(hasTokens, readonly, false));
    }

    return sections;
  }

  private inProgressSections() {
    const sections = [];
    const {
      isHarvest,
      isAgricultural,
      readonly,
      allowProgress,
      isInfrastructural,
      hasEmployees,
      hasMachinery,
      hasInputs,
      hasTools,
      allowTokens,
      isMachineryMaintenance,
      isTransitAndTransport,
      isProcessing,
      isCompleted,
      showEmployeeProgress,
    } = this.workOrderState();

    sections.push(Attendance(hasEmployees, readonly, showEmployeeProgress));

    if (!isMachineryMaintenance || !isTransitAndTransport) {
      sections.push(UsedMachines(hasMachinery));
    }

    sections.push(
      isProcessing
        ? ProcessingPlants(this.builder)
        : isAgricultural
        ? CropFields(true, this.builder)
        : isInfrastructural
        ? Infrastructures(this.builder)
        : isMachineryMaintenance || isTransitAndTransport
        ? MachineMaintenance(this.builder)
        : CostCenters(this.builder)
    );

    sections.push(
      ConsumedInputs(
        hasInputs,
        readonly,
        this.builder.workOrder.usageByManual || !isAgricultural,
        allowProgress
      )
    );

    sections.push(UsedTools(hasTools, readonly));

    if (isProcessing) {
      sections.splice(1, 0, ProcessOutputs());
    }

    if (isHarvest) {
      if (allowTokens) {
        sections.splice(
          0,
          0,
          Tokens(this.builder.workOrder.tokens.length > 0, readonly, true)
        );
      }

      sections.push(
        Outputs(true, readonly, !!this.builder.workOrder.harvestByOutput)
      );
    }

    if (isCompleted) {
      sections.splice(0, 0, Summary());
    }

    return sections;
  }
}

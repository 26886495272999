import { FormInstance } from "antd/lib/form";
import { cloneDeep } from "lodash";
import { MessageDescriptor } from "react-intl";
import { getAreaConversionFactor } from "../../../../lib/formats";
import {
  ActivityLaborForceType,
  ActivityProgressMeasureType,
  CurrentUser,
  PlanningDetailsFragment,
  UnitType,
  WorkOrderCategory,
  WorkOrderDetailsFragment,
  WorkOrderEmployeeFragment,
  WorkOrderMachineFragment,
  WorkOrderStatus,
  WorkOrderType,
  WorkOrderVariantFragment,
} from "../../../../lib/graphql";
import { Destroy } from "../../../../lib/hooks";
import { WorkOrderCostCenter, WorkOrderCostCenterBuilder } from "./costCenters";
import { WorkOrderEmployeeBuilder } from "./employees";
import { WorkOrderInputBuilder } from "./inputs";
import { WorkOrderMachineBuilder } from "./machines";
import { WorkOrderOutput, WorkOrderOutputBuilder } from "./outputs";
import { WorkOrderSectionBuilder } from "./sections";
import { WorkOrderTokenBuilder } from "./tokens";
import { WorkOrderToolBuilder, WorkOrderVariant } from "./tools";

export type WorkOrderFormValues = Omit<
  WorkOrderDetailsFragment,
  | "employees"
  | "costCenters"
  | "inputs"
  | "tools"
  | "tokens"
  | "machines"
  | "outputs"
> & {
  nextStatus?: WorkOrderStatus;
  successMessage?: MessageDescriptor;
  employees: Array<Destroy<WorkOrderEmployeeFragment>>;
  costCenters: Array<WorkOrderCostCenter>;
  inputs: Array<WorkOrderVariant>;
  tools: Array<WorkOrderVariant>;
  tokens: Array<WorkOrderVariant>;
  outputs: Array<
    Destroy<WorkOrderVariantFragment & { returnWarehouseId?: string }>
  >;
  processingInputs: Array<
    Destroy<WorkOrderOutput & { sourceWarehouseId?: string }>
  >;
  processingOutputs: Array<
    Destroy<WorkOrderVariantFragment & { returnWarehouseId?: string }>
  >;
  machines: Array<Destroy<WorkOrderMachineFragment>>;
  [key: string]: any;
};

export class WorkOrderBuilder {
  workOrder: WorkOrderFormValues;
  user?: CurrentUser;
  planning?: PlanningDetailsFragment;
  form: FormInstance<WorkOrderFormValues>;

  sections: WorkOrderSectionBuilder;

  costCenters: WorkOrderCostCenterBuilder;
  employees: WorkOrderEmployeeBuilder;
  machines: WorkOrderMachineBuilder;
  inputs: WorkOrderInputBuilder;
  tools: WorkOrderToolBuilder;
  tokens: WorkOrderTokenBuilder;
  outputs: WorkOrderOutputBuilder;

  constructor(
    workOrder: WorkOrderDetailsFragment,
    form: FormInstance<WorkOrderFormValues>,
    user?: CurrentUser,
    planning?: PlanningDetailsFragment
  ) {
    this.workOrder = this.buildFormValue(workOrder);
    this.user = user;
    this.form = form;
    this.planning = planning;

    this.costCenters = new WorkOrderCostCenterBuilder(this);
    this.employees = new WorkOrderEmployeeBuilder(this);
    this.machines = new WorkOrderMachineBuilder(this);
    this.inputs = new WorkOrderInputBuilder(this);
    this.tools = new WorkOrderToolBuilder(this);
    this.tokens = new WorkOrderTokenBuilder(this);
    this.outputs = new WorkOrderOutputBuilder(this);
    this.sections = new WorkOrderSectionBuilder(this);
  }

  buildFormValue(workOrder: WorkOrderDetailsFragment) {
    return {
      ...cloneDeep(workOrder),
      progressByEmployee:
        workOrder.progressByEmployee == undefined && !!workOrder.activity.id
          ? workOrder.activity.progressMeasureType ===
            ActivityProgressMeasureType.ByIndividual
          : workOrder.progressByEmployee,
      activityId: workOrder.activity.id,
      cropCycleId: workOrder.cropCycle?.id,
      assignedToId: workOrder.assignedTo?.id,
      processingInputs: [],
      processingOutputs: [],
    };
  }

  init() {
    if (this.isNew) return;

    this.costCenters.init();
  }

  get progress() {
    return this.isMachinery ? this.machines.progress : this.employees.progress;
  }

  get isSubmitting() {
    return !!this.form.getFieldValue("nextStatus");
  }

  get isCompleting() {
    return this.form.getFieldValue("nextStatus") == WorkOrderStatus.Completed;
  }

  get noActivity() {
    return !this.workOrder.activity.id;
  }

  get canEditActivity() {
    return this.isOpen && !this.isReadonly;
  }

  get isDataIntake() {
    return (
      this.workOrder.status === WorkOrderStatus.InProgress ||
      this.workOrder.status === WorkOrderStatus.Completed ||
      this.workOrder.status === WorkOrderStatus.Reverted
    );
  }

  get isOpen() {
    return this.workOrder.status === WorkOrderStatus.Open;
  }

  get isScheduled() {
    return this.workOrder.status === WorkOrderStatus.Scheduled;
  }

  get isInProgress() {
    return this.workOrder.status === WorkOrderStatus.InProgress;
  }

  get isCompleted() {
    return this.workOrder.status === WorkOrderStatus.Completed;
  }

  get isReadonly() {
    return (
      !this.user?.permissions?.workOrder?.write ||
      this.workOrder.status === WorkOrderStatus.Completed ||
      this.workOrder.status === WorkOrderStatus.Canceled ||
      this.workOrder.status === WorkOrderStatus.Reverted ||
      this.workOrder.status === WorkOrderStatus.Completing
    );
  }

  get showDayGoal() {
    return this.allowProgress && !this.isHarvest;
  }

  get isMachinery() {
    return (
      this.workOrder.activity.laborForceType === ActivityLaborForceType.Machine
    );
  }

  get isAgricultural() {
    return (
      this.workOrder.activity.workOrderCategory ===
      WorkOrderCategory.Agricultural
    );
  }

  get isInfrastructural() {
    return (
      this.workOrder.activity.workOrderCategory ===
      WorkOrderCategory.Infrastructural
    );
  }

  get isMachineryMaintenance() {
    return this.workOrder.activity.workOrderType === WorkOrderType.Repair;
  }

  get isTransitAndTransport() {
    return this.workOrder.activity.workOrderType === WorkOrderType.Transport;
  }

  get isHarvest() {
    return this.workOrder.activity.workOrderType === WorkOrderType.Harvest;
  }

  get isProcessing() {
    return this.workOrder.activity.workOrderType === WorkOrderType.Processing;
  }

  get isNew() {
    return !this.workOrder.id;
  }

  get allowProgress() {
    return this.workOrder.activity.progressUnit.unitType !== UnitType.Time;
  }

  get allowDosage() {
    return this.allowProgress && this.isAgricultural;
  }

  get canEdit() {
    return (
      this.workOrder.status === WorkOrderStatus.Open ||
      this.workOrder.status === WorkOrderStatus.Scheduled ||
      this.workOrder.status === WorkOrderStatus.InProgress
    );
  }

  get canCancel() {
    return (
      this.workOrder.status !== WorkOrderStatus.Completed &&
      this.workOrder.status !== WorkOrderStatus.Reverted
    );
  }

  get canSubmit() {
    return this.inputs.hasItems || this.tools.hasItems || this.tokens.hasItems;
  }

  get canRevert() {
    return (
      this.workOrder.status === WorkOrderStatus.Completed &&
      this.user?.permissions.workOrder?.delete
    );
  }

  get disableWorkerCount() {
    return this.isHarvest && this.user?.currentTenant.allowTokens;
  }

  get inventoryRequestDisabled() {
    return this.user?.currentTenant.inventoryRequestDisabled;
  }

  get progressByIndividual() {
    return !!this.workOrder.progressByEmployee;
  }

  get showEmployeeProgress() {
    return !this.isMachinery && this.allowProgress && this.progressByIndividual;
  }

  get progressByGroup() {
    return !this.progressByIndividual;
  }

  get distributeProgress() {
    return this.progressByIndividual || this.tokens.hasItems;
  }

  get workerCountMode() {
    return !!this.workOrder.workerCount && !this.workOrder.tokens.length;
  }

  get areaConversionFactor() {
    return getAreaConversionFactor(
      this.workOrder.locality.areaUnit,
      this.workOrder.activity.progressUnit
    );
  }
}

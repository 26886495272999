import { FormattedMessage } from "react-intl";
import { CounterpartyChanges, CounterpartyFields } from "../../lib/graphql";
import { SaveIDFunction } from "../../lib/hooks";
import routes, { routerPush } from "../../lib/routes";
import { Form } from "../form";
import { BottomPanel, MenuContent } from "../shared";
import CounterpartyBasic from "./CounterpartyBasic";
import { CounterpartyCustomer } from "./CounterpartyCustomer";
import { CounterpartyVendor } from "./CounterpartyVendor";

type CounterpartyFormValues = CounterpartyFields & CounterpartyChanges;

interface CounterpartyFormProps {
  counterparty: CounterpartyFormValues;
  onSave?(values: CounterpartyFormValues): SaveIDFunction;
}

export function CounterpartyForm({
  counterparty,
  onSave,
}: CounterpartyFormProps) {
  const disabled = !counterparty.name;

  return (
    <Form
      preventLeaving
      layout="vertical"
      initialValues={counterparty}
      onSubmit={(values, { setSubmitting, showErrors }) => {
        if (!onSave) return;

        const promise = onSave(values);
        if (!promise) return;

        promise.then((f) => {
          if (!f) return;

          if (f.result) {
            routerPush(f.result.id, routes.counterparties.details);
          } else if (f.errors) {
            showErrors(f.errors);
            setSubmitting(false);
          }
        });
      }}
    >
      <MenuContent
        items={{
          basic: {
            title: <FormattedMessage id="basicInfo" />,
            render: () => <CounterpartyBasic />,
          },
          advanced: {
            title: (
              <FormattedMessage
                id="advancedSettings"
                defaultMessage="advancedSettings"
              />
            ),
            group: "advanced",
          },
          vendor: {
            title: (
              <FormattedMessage
                id="counterparties.vendorConfig"
                defaultMessage="vendorConfig"
              />
            ),
            group: "advanced",
            disabled,
            render: () => <CounterpartyVendor />,
          },
          customer: {
            title: (
              <FormattedMessage
                id="counterparties.customerConfig"
                defaultMessage="customerConfig"
              />
            ),
            group: "advanced",
            disabled,
            render: () => <CounterpartyCustomer />,
          },
        }}
      />

      <BottomPanel
        buttons={[
          BottomPanel.CancelButton({
            route: routes.counterparties.index,
          }),
          BottomPanel.SubmitButton(),
        ]}
      />
    </Form>
  );
}

import { EyeInvisibleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, FormItemProps, Input, Row, Tag, Tooltip } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import { ItemDetailsFragment, ItemKind } from "../../lib/graphql";
import { Form, DeepNamePath, Rules } from "../form";
import { Popover } from "../shared";
import { UnitAmountInput } from "../units";

interface ItemVariantInput {
  name: DeepNamePath;
  formItemProps?: FormItemProps;
  item?: ItemDetailsFragment;
}

export function ItemVariantsInput({
  name,
  item,
  formItemProps,
}: ItemVariantInput) {
  const intl = useIntl();

  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue, setFields }) => {
        const variationUnitTypeValue = getFieldValue("variationUnitType");

        if (!getFieldValue("itemCategoryId") || !variationUnitTypeValue)
          return null;

        return (
          <Form.Item
            label={<FormattedMessage id="items.packaging" />}
            name="variants"
            required
            {...formItemProps}
          >
            <Form.List
              name={name}
              addText={
                <FormattedMessage
                  id="items.addVariant"
                  defaultMessage="Add Variant"
                />
              }
              renderItem={({ key, name: index }, removeIcon) => {
                const variantId = getFieldValue(name.concat(index, "id"));
                const variant = item?.variants.find((v) => v.id == variantId);
                const discardedAt = getFieldValue(
                  name.concat(index, "discardedAt")
                );
                const token = variant?.token;

                return (
                  <Row
                    key={key}
                    align="middle"
                    gutter={8}
                    style={{ marginBottom: "8px" }}
                  >
                    <Col span={token ? 10 : 12}>
                      <Form.Item
                        compact
                        name={[index, "variationValue"]}
                        rules={[Rules.required]}
                      >
                        <UnitAmountInput
                          isInteger={
                            getFieldValue("kind") === ItemKind.Reusable
                          }
                          unitType={variationUnitTypeValue}
                          amountName={[index, "variationValue"]}
                          unitName={[index, "variationUnitId"]}
                          width={["40%", "60%"]}
                          amountNumberProps={{
                            placeholder: intl.formatMessage({
                              id: "items.packaging",
                              defaultMessage: "Packaging",
                            }),
                          }}
                          disabled={variant && !variant.mayUpdateVariationValue}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item compact name={[index, "internalId"]}>
                        <Input
                          placeholder={intl.formatMessage({
                            id: "internalId",
                          })}
                          disabled={discardedAt != null}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      {discardedAt ? (
                        <Button
                          type="dashed"
                          icon={<PlusCircleOutlined />}
                          onClick={() => {
                            setFields([
                              {
                                name: name.concat(index, "discardedAt"),
                                value: null,
                              },
                            ]);
                          }}
                        >
                          <span>
                            <FormattedMessage id="undiscard" />
                          </span>
                        </Button>
                      ) : variant?.mayDestroy || !variant?.id ? (
                        removeIcon
                      ) : (
                        <Popover
                          trigger="hover"
                          readonly={variant?.mayDiscard}
                          content={
                            <FormattedMessage
                              id="variants.discardHint"
                              defaultMessage="discardHint"
                            />
                          }
                        >
                          <Button
                            type="dashed"
                            danger
                            disabled={!variant?.mayDiscard}
                            icon={<EyeInvisibleOutlined />}
                            block
                            onClick={() => {
                              setFields([
                                {
                                  name: name.concat(index, "discardedAt"),
                                  value: new Date(),
                                },
                              ]);
                            }}
                          >
                            <span>
                              <FormattedMessage id="discard" />
                            </span>
                          </Button>
                        </Popover>
                      )}
                    </Col>
                    {token && (
                      <Col span={2}>
                        <Tooltip
                          title={<FormattedMessage id="tokens.denomination" />}
                        >
                          <Tag color="gold">{token.quantity}</Tag>
                        </Tooltip>
                      </Col>
                    )}
                  </Row>
                );
              }}
            />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
}

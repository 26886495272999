import { ChangeList, MenuContent } from "../../shared";
import { ActivityBasicForm } from "./ActivityBasicForm";
import { FormattedMessage } from "react-intl";
import { ActivityBasicInfo } from "./ActivityBasicInfo";
import { ActivityDetailsFragment } from "../../../lib/graphql";
import { ActivityPayments } from "./ActivityPayments";
import { Col, Row } from "antd";
import { ActivityWorkOrderSettings } from "./ActivityWorkOrderSettings";
import { usePermissions } from "../../../lib/hooks";

interface ActivityContentProps {
  mode: "readonly" | "edit" | "new";
  activity?: ActivityDetailsFragment;
  onChange?(key: string): void;
}

export function ActivityContent({
  mode,
  activity,
  onChange,
}: ActivityContentProps) {
  const disabled = mode === "new";
  const readonly = mode === "readonly";
  const showWage = usePermissions((p) => p.settings?.showWage);

  const items: Record<string, any> = {
    basic: {
      title: <FormattedMessage id="basicInfo" />,
      render: () =>
        !readonly ? (
          <Row>
            <Col md={12}>
              <ActivityBasicForm activity={activity} />
            </Col>
          </Row>
        ) : (
          activity && <ActivityBasicInfo activity={activity} />
        ),
    },
    advanced: {
      title: (
        <FormattedMessage
          id="advancedSettings"
          defaultMessage="advancedSettings"
        />
      ),
      group: "advanced",
    },
    workOrder: {
      title: <FormattedMessage id="workOrders.entityName" />,
      disabled,
      group: "advanced",
      render: () =>
        activity && (
          <ActivityWorkOrderSettings activity={activity} readonly={readonly} />
        ),
    },
    payment: {
      title: (
        <FormattedMessage
          id="activities.paymentRules"
          defaultMessage="Payment Rules"
        />
      ),
      disabled: disabled || !showWage,
      group: "advanced",
      render: () =>
        activity && (
          <ActivityPayments activity={activity} readonly={readonly} />
        ),
    },
    history: {
      title: <FormattedMessage id="history" />,
      render: () =>
        activity && <ChangeList id={activity.id} type="Agro::Activity" />,
    },
  };

  if (!readonly) delete items.history;

  return <MenuContent onChange={onChange} items={items} />;
}

import Icon from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { WorkOrderConsumedInputs } from "../../WorkOrderConsumedInputs";
import { WorkOrderInputs } from "../../WorkOrderInputs";

export function Inputs(
  visible: boolean,
  readonly: boolean,
  requestByTotal: boolean,
  allowDosage: boolean,
  hidden: boolean
) {
  const views = [
    {
      title: <FormattedMessage id="workOrders.inputs" />,
      description: (
        <FormattedMessage
          id="workOrders.inputsByTotal.hint"
          defaultMessage="hint"
        />
      ),
      menuItem: (
        <FormattedMessage
          id="workOrders.requestTotalAmount"
          defaultMessage="requestTotalAmount"
        />
      ),
      render: () => (
        <WorkOrderInputs
          readonly={readonly}
          mode="requestTotal"
          noDosages={!allowDosage}
        />
      ),
    },
  ];

  if (allowDosage) {
    views.splice(0, 0, {
      title: <FormattedMessage id="workOrders.inputs" />,
      description: (
        <FormattedMessage
          id="workOrders.inputsByDosage.hint"
          defaultMessage="hint"
        />
      ),
      menuItem: (
        <FormattedMessage
          id="workOrders.requestByDosage"
          defaultMessage="requestByDosage"
        />
      ),
      render: () => (
        <WorkOrderInputs readonly={readonly} mode="requestByDosage" />
      ),
    });
  } else {
    views.push({
      title: <FormattedMessage id="workOrders.inputs" />,
      description: (
        <FormattedMessage
          id="workOrders.inputsBySubtotals.hint"
          defaultMessage="hint"
        />
      ),
      menuItem: (
        <FormattedMessage
          id="workOrders.requestSubtotalAmount"
          defaultMessage="requestSubtotalAmount"
        />
      ),
      render: () => (
        <WorkOrderInputs
          readonly={readonly}
          mode="requestBySubtotal"
          noDosages={true}
        />
      ),
    });
  }

  return {
    key: "inputs",
    visible,
    icon: <Icon component={() => <img src="/images/icons/inputs.svg" />} />,
    name: <FormattedMessage id="workOrders.inputs" defaultMessage="inputs" />,
    viewProperty: "requestByTotal",
    currentViewIndex: requestByTotal ? 1 : 0,
    views,
    hidden,
  };
}

export function ConsumedInputs(
  visible: boolean,
  readonly: boolean,
  usageByManual: boolean,
  allowProgress: boolean
) {
  return {
    key: "input-usage",
    visible,
    icon: <Icon component={() => <img src="/images/icons/inputs.svg" />} />,
    name: <FormattedMessage id="workOrders.inputs" defaultMessage="inputs" />,
    viewProperty: "usageByManual",
    currentViewIndex: usageByManual ? 1 : 0,
    views: [
      {
        title: allowProgress ? (
          <FormattedMessage
            id="workOrders.inputsByProgress"
            defaultMessage="distributeByProgress"
          />
        ) : (
          <FormattedMessage
            id="workOrders.distributeAutomatically"
            defaultMessage="distributeAutomatically"
          />
        ),
        menuItem: allowProgress ? (
          <FormattedMessage
            id="workOrders.inputsByProgress.menuItem"
            defaultMessage="distributeByProgress"
          />
        ) : (
          <FormattedMessage
            id="workOrders.distributeAutomatically.menuItem"
            defaultMessage="distributeAutomatically"
          />
        ),
        description: allowProgress ? (
          <FormattedMessage
            id="workOrders.inputsByProgress.hint"
            defaultMessage="hint"
          />
        ) : (
          <FormattedMessage
            id="workOrders.distributeAutomatically.hint"
            defaultMessage="hint"
          />
        ),
        render: () => (
          <WorkOrderConsumedInputs
            readonly={readonly}
            byProgress={true}
            noDosages={!allowProgress}
          />
        ),
      },
      {
        title: allowProgress ? (
          <FormattedMessage
            id="workOrders.inputsManual"
            defaultMessage="distributeManually"
          />
        ) : (
          <FormattedMessage
            id="workOrders.distributeByCostCenter"
            defaultMessage="distributeByCostCenter"
          />
        ),
        menuItem: allowProgress ? (
          <FormattedMessage
            id="workOrders.inputsManual.menuItem"
            defaultMessage="distributeManually"
          />
        ) : (
          <FormattedMessage
            id="workOrders.distributeByCostCenter.menuItem"
            defaultMessage="distributeByCostCenter"
          />
        ),
        description: allowProgress ? (
          <FormattedMessage
            id="workOrders.inputsManual.hint"
            defaultMessage="hint"
          />
        ) : (
          <FormattedMessage
            id="workOrders.distributeByCostCenter.hint"
            defaultMessage="hint"
          />
        ),
        render: () => (
          <WorkOrderConsumedInputs
            readonly={readonly}
            byProgress={false}
            noDosages={!allowProgress}
          />
        ),
      },
    ],
  };
}

import { TransferFragment, TransferStatus } from "../../lib/graphql";
import { ActionButton } from "../shared";
import { useItemSidebarContext, usePermissions } from "../../lib/hooks";
import { formatPdfPath } from "../../lib/formats";
import { ItemType } from "antd/lib/menu/hooks/useItems";

export function TransferActionButton({
  transfer,
  entityName,
}: {
  transfer: TransferFragment;
  entityName: string;
}) {
  const { setCurrentAction } = useItemSidebarContext();

  const permissions = usePermissions((p) => p.transfer);

  if (
    transfer.status === TransferStatus.Completing ||
    transfer.status === TransferStatus.Reverting
  )
    return null;

  const isOpeningStock = transfer.adjustmentType.entryCode === "OS";

  const actions: ItemType[] = [
    ActionButton.CopyLinkMenuItem(),
    ActionButton.ChangeLog(() => setCurrentAction("changeLog")),
  ];

  if (permissions?.write) {
    actions.push(
      ActionButton.Clone({
        onClick: () => setCurrentAction("clone"),
        entityName,
      })
    );
  }

  if (transfer.status === TransferStatus.Open) {
    if (permissions?.write) {
      actions.push(
        { type: "divider" },
        ActionButton.Cancel(() => setCurrentAction("cancel"))
      );
    }
  } else if (transfer.status === TransferStatus.Completed) {
    actions.push(
      ActionButton.DownloadPDF({
        downloadLink: formatPdfPath("inventory_transfer", transfer.id),
      })
    );

    if (!isOpeningStock && permissions?.delete) {
      actions.push(
        { type: "divider" },
        ActionButton.Revert(() => setCurrentAction("revert"))
      );
    }
  }

  return <ActionButton menuItems={actions} />;
}
